import { BreadcrumbGroup, Button, ButtonDropdown, ButtonDropdownProps, Container, ContentLayout, Header, SpaceBetween } from "@amzn/awsui-components-react"
import { useOpportunityTaskContext } from "context/OpportunityContext"
import { Opportunity, OPPORTUNITY_STAGE } from "models/opportunity"
import MainLayout from "pmsa-polaris/components/MainLayout"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import qs from "qs"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { routeParams } from "routes"

import ActivitiesContainer from "./ActivitiesContainer"
import DetailsSection from "./DetailsSection"
import HygieneDetails from "./HygieneDetails"
import LineItemTable from "./LineItemTable"

const { sfdcBaseUrl } = config

const OpportunityDetails = () => {
  const { id: idParam } = useParams()
  const { alias } = useQueryString()
  const id = idParam!

  const navigate = useNavigate()

  const { opportunityList, getOpportunity, updateOpportunity } = useOpportunityTaskContext()

  const [opportunity, setOpportunity] = useState<Opportunity | undefined>(
    id && opportunityList.data ? opportunityList.data.find((opp) => opp.id === id) : undefined
  )
  const [opportunityError, setOpportunityError] = useState(false)

  useEffect(() => {
    if (!opportunityList.loading && !opportunity && !opportunityError)
      getOpportunity(id)
        .then((opp) => setOpportunity(opp))
        .catch(() => setOpportunityError(true))
  }, [opportunityList, opportunity, getOpportunity, id, opportunityError])

  const setFlashMessages = useFlashbar()

  const opportunityQuickExtendHandler = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    if (opportunity?.closeDate != undefined && event.detail.id.startsWith("ext")) {
      const extendableHygieneIssues = ["Asleep", "Forgotten", "Stale", "Neglected"]

      if (
        opportunity.hygiene.length == 0 ||
        opportunity.hygiene.every((hygieneString) => extendableHygieneIssues.some((validHygiene) => hygieneString === validHygiene))
      ) {
        const newDate = new Date(opportunity.closeDate)
        const newOpp: Opportunity = opportunity
        newDate.setDate(newDate.getDate() + parseInt(event.detail.id.substring(3)))
        switch (newOpp.stageName) {
          case OPPORTUNITY_STAGE[7]: // Completed
            newOpp.stageName = OPPORTUNITY_STAGE[1]
            break
          case OPPORTUNITY_STAGE[8]: // Closed Lost
            newOpp.stageName = OPPORTUNITY_STAGE[1]
            newOpp.closedLostCategory = ""
            newOpp.closedLostReasonNotes = ""
            break
        }
        newOpp.closeDate = newDate.toISOString().split("T")[0]

        await updateOpportunity(newOpp.id, newOpp)
          .then(() => {
            setFlashMessages([
              {
                type: "success",
                content: "Opportunity updated successfully!",
              },
            ])
          })
          .catch((error) => {
            setFlashMessages([
              {
                type: "error",
                content: "Opportunity update failed!" + (error?.response?.data?.errorCode ? ` (${error?.response?.data?.errorCode})` : ""),
              },
            ])
          })
        getOpportunity(id)
      } else {
        setFlashMessages([
          {
            type: "error",
            content: "Opportunity has hygiene issues and cannot be updated!",
          },
        ])
      }
    }
  }

  return (
    <MainLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Opportunities", href: "/opportunities" },
            { text: id, href: "" },
          ]}
          onFollow={(e) => {
            e.preventDefault()
            navigate(e.detail.href)
          }}
        />
      }
    >
      <ContentLayout header={<Header>Opportunity Details</Header>}>
        <SpaceBetween size="l">
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="m">
                    <Button iconAlign="right" iconName="external" href={`${sfdcBaseUrl}/${id ?? ""}`} target="_blank">
                      View in SFDC
                    </Button>
                    <NavigationButton disabled={opportunityList.loading} href={`${routeParams.opportunitiesCreate()}?${qs.stringify(opportunity)}`}>
                      New copy
                    </NavigationButton>
                    <ButtonDropdown
                      items={[
                        { text: "30 days", id: "ext30" },
                        { text: "60 days", id: "ext60" },
                        { text: "90 days", id: "ext90" },
                      ]}
                      onItemClick={(e) => opportunityQuickExtendHandler(e)}
                    >
                      Extend Close Date
                    </ButtonDropdown>
                    <NavigationButton iconAlign="right" iconName="edit" variant="primary" href={routeParams.opportunitiesEdit({ id })}>
                      Edit
                    </NavigationButton>
                    <Button
                      iconName="refresh"
                      variant="icon"
                      onClick={(e) => {
                        e.preventDefault()
                        getOpportunity(id)
                      }}
                    />
                  </SpaceBetween>
                }
              >
                {opportunity?.title || "Opportunity"}
              </Header>
            }
          >
            <DetailsSection opportunity={opportunity} error={opportunityList.error} loading={opportunityList.loading} />
          </Container>
          <HygieneDetails loading={opportunityList.loading} error={opportunityList.error} opportunity={opportunity} />
          <LineItemTable loading={opportunityList.loading} error={opportunityList.error} opportunity={opportunity} />
          <ActivitiesContainer opportunity={opportunity} loading={opportunityList.loading} error={opportunityList.error} />
        </SpaceBetween>
      </ContentLayout>
    </MainLayout>
  )
}

export default OpportunityDetails
