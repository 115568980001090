import MainLayout from "pmsa-polaris/components/MainLayout"

import CustomerOpportunitiesTable from "./CustomerOpportunityTable"
import ToolsPanel from "./ToolsPanel"

const CustomerOpportunities: React.FC = () => {
  return (
    <MainLayout contentType="table" tools={<ToolsPanel />}>
      <br />
      <CustomerOpportunitiesTable />
    </MainLayout>
  )
}

export default CustomerOpportunities
