import { PropertyFilterOperator, PropertyFilterToken, useCollection } from "@amzn/awsui-collection-hooks"
import {
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  Calendar,
  CollectionPreferences,
  CollectionPreferencesProps,
  DateInput,
  FormField,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react"
import { useOpportunityTaskContext } from "context/OpportunityContext"
import { TASK_STATUS } from "models/task"
import EmptyState from "pmsa-polaris/components/EmptyState"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import usePersistentState from "pmsa-polaris/hooks/usePersistentState"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import { paginationLabels } from "pmsa-polaris/utils"
import qs from "qs"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import routes, { routeParams } from "routes"

import { DEFAULT_COLUMN_IDS } from "./columnNames"
import * as tableConfig from "./tableConfig"

const { sfdcBaseUrl } = config

const TasksTable = () => {
  const location = useLocation()
  const queryParams = useQueryString()
  const navigate = useNavigate()

  const { filteringParams = {}, ...searchParamsObject } = queryParams

  // context providers
  const { taskList, getTaskList: getTasks } = useOpportunityTaskContext()

  // error message
  const [errorMessage, setErrorMessage] = useState("Failed to fetch tasks")

  useEffect(() => {
    if (taskList.error && taskList.error.response?.data?.errorCode == "USER_NOT_FOUND") {
      taskList.error.response?.data?.message && setErrorMessage(taskList.error.response.data.message)
    }
  }, [taskList.error])

  // table preferences
  const taskTablePreferences = "thunder.taskTablePreferences"

  const [preferences, setPreferences] = usePersistentState<CollectionPreferencesProps.Preferences>(taskTablePreferences, {
    pageSize: 10,
    visibleContent: DEFAULT_COLUMN_IDS,
    wrapLines: false,
  })

  // actions button handler
  const actionsHandler = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    switch (event.detail.id) {
      case "view-in-sfdc": {
        selectedItems?.forEach((item) => {
          window.open(`${sfdcBaseUrl}/${item.id}`)
        })
        break
      }
      case "view-details": {
        selectedItems?.forEach((item) => {
          window.open(routeParams.tasksDetails({ id: item.id }))
        })
        break
      }
      case "edit": {
        selectedItems?.forEach((item) => {
          window.open(routeParams.tasksEdit({ id: item.id }))
        })
        break
      }
      default: {
        break
      }
    }
  }

  const dateForm = ({ value, onChange }: any) => (
    <div className="date-form">
      <FormField>
        <DateInput value={value ?? ""} onChange={(e) => onChange(e.detail.value ?? "")} placeholder="YYYY/MM/DD" />
      </FormField>
      <Calendar value={value ? value.replace("/", "-") : ""} onChange={(e) => onChange(e.detail.value ?? "")} locale="en-GB" />
    </div>
  )

  // tasks collection
  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(taskList.data || [], {
    propertyFiltering: {
      empty: (
        <EmptyState
          errorText={(taskList.error && errorMessage) || undefined}
          title="No tasks"
          subtitle="No tasks to display."
          action={
            <NavigationButton variant="primary" href={routes.tasksCreate}>
              Create Task
            </NavigationButton>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can't find a match."
          action={
            <Button
              onClick={() => {
                actions.setPropertyFiltering({ operation: "and", tokens: [] })
              }}
            >
              Clear filter
            </Button>
          }
        />
      ),
      filteringProperties: [
        {
          propertyLabel: "Activity Date",
          groupValuesLabel: "Activity Date Values",
          key: "activityDate",
          operators: (["=", "!=", "<", "<=", ">", ">="] as PropertyFilterOperator[]).map((operator) => ({
            operator,
            form: dateForm,
            match: (item, token) => {
              const [d1, d2] = [Date.parse(item as string), Date.parse(token)]
              switch (operator) {
                case "=":
                  return d1 === d2
                case "!=":
                  return d1 !== d2
                case "<":
                  return d1 < d2
                case "<=":
                  return d1 <= d2
                case ">":
                  return d1 > d2
                case ">=":
                  return d1 >= d2
                default:
                  return false
              }
            },
          })),
        },
        {
          propertyLabel: "BD Activity Type",
          groupValuesLabel: "BD Activity Type Values",
          key: "bdActivityType",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Task Name",
          groupValuesLabel: "Task Values",
          key: "subject",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Partner Name",
          groupValuesLabel: "Partner Values",
          key: "accountName",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Status",
          groupValuesLabel: "Status Values",
          key: "status",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
      ],
    },
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "lastModifiedDate",
        },
        isDescending: true,
      },
    },
    selection: {},
  })

  // set initial property filter options
  useEffect(() => {
    if (!filteringParams || Object.keys(filteringParams).length === 0)
      actions.setPropertyFiltering({
        operation: "and",
        tokens: [
          {
            propertyKey: "status",
            operator: "!=",
            value: TASK_STATUS[2], // "Completed"
          },
          {
            propertyKey: "status",
            operator: "!=",
            value: TASK_STATUS[7], // "Closed Lost"
          },
        ],
      })
    else {
      const tokens: PropertyFilterToken[] = []

      Object.entries(filteringParams).forEach(([key, value]) => {
        // start and end dates for closeDate field
        if (key === "activityDateStart") {
          tokens.push({
            propertyKey: "activityDate",
            operator: ">=",
            value: value,
          })
          return
        }
        if (key === "activityDateEnd") {
          tokens.push({
            propertyKey: "activityDate",
            operator: "<=",
            value: value,
          })
          return
        }

        // if key starts with '!', operator is '!='
        // values can be comma separated list of values
        if (key.startsWith("!"))
          (value as string).split(",").forEach((v) => {
            tokens.push({
              propertyKey: key.substring(1),
              operator: "!=",
              value: v,
            })
          })
        else
          (value as string).split(",").forEach((v) => {
            tokens.push({
              propertyKey: key,
              operator: "=",
              value: v,
            })
          })
      })

      actions.setPropertyFiltering({
        operation: "and",
        tokens: tokens,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { selectedItems } = collectionProps

  // tasks table component
  return (
    <Table
      {...collectionProps}
      loading={taskList.loading}
      loadingText="Loading tasks"
      selectionType="multi"
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <Header
          variant="h2"
          counter={(taskList.data?.length && (selectedItems ? `(${selectedItems.length}/${taskList.data?.length})` : `(${taskList.data?.length})`)) || ""}
          actions={
            <SpaceBetween direction="horizontal" size="s">
              <ButtonDropdown
                items={[
                  {
                    id: "view-in-sfdc",
                    text: "View in SFDC",
                    disabled: selectedItems?.length == 0,
                    external: true,
                  },
                  {
                    id: "view-details",
                    text: "View Details",
                    disabled: selectedItems?.length == 0,
                  },
                  {
                    id: "edit",
                    text: "Edit",
                    disabled: selectedItems?.length == 0,
                  },
                  {
                    id: "new-copy",
                    text: "New Copy",
                    disabled: selectedItems?.length != 1,
                    href: `${routeParams.tasksCreate()}?${qs.stringify(selectedItems && selectedItems[0])}`,
                  },
                ]}
                expandableGroups
                onItemClick={(event) => actionsHandler(event)}
              >
                Actions
              </ButtonDropdown>
              <NavigationButton variant="primary" href={routes.tasksCreate}>
                Create Task
              </NavigationButton>
              <Button
                iconName="refresh"
                variant="icon"
                onClick={(e) => {
                  e.preventDefault()
                  getTasks()
                }}
              />
            </SpaceBetween>
          }
        >
          Tasks (Activities)
        </Header>
      }
      visibleColumns={preferences.visibleContent}
      columnDefinitions={tableConfig.COLUMN_DEFINITIONS}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          i18nStrings={{
            applyActionText: "Apply",
            clearFiltersText: "Clear",
            groupPropertiesText: "Properties",
            groupValuesText: "Values",
            operationAndText: "And",
            operationOrText: "Or",
            operatorsText: "Operators",
            operatorText: "Operator",
            propertyText: "Property",
            valueText: "Value",
          }}
        />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: "Page size",
            options: tableConfig.PAGE_SIZE_OPTIONS,
          }}
          wrapLinesPreference={{
            label: "Wrap lines",
            description: "Check to see all the text and wrap the lines",
          }}
          visibleContentPreference={{
            title: "Select visible columns",
            options: tableConfig.VISIBLE_CONTENT_OPTIONS,
          }}
        />
      }
    />
  )
}

export default TasksTable
