import MainLayout from "pmsa-polaris/components/MainLayout"

import OpportunitiesTable from "./OpportunitiesTable"
import ToolsPanel from "./ToolsPanel"

const Opportunities: React.FC = () => {
  return (
    <MainLayout contentType="table" tools={<ToolsPanel />}>
      <OpportunitiesTable />
    </MainLayout>
  )
}

export default Opportunities
