import Link from "@amzn/awsui-components-react/polaris/link"
import { TableProps } from "@amzn/awsui-components-react/polaris/table"
import { TaskResponse } from "api/tasks"
import NavigationLink from "pmsa-polaris/components/NavigationLink"
import config from "pmsa-polaris/config"
import routes, { routeParams } from "routes"

import { VISIBLE_COLUMN_OPTIONS } from "./columnNames"

const { sfdcBaseUrl } = config

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<TaskResponse>[] = [
  {
    id: "id",
    cell: (item) => <NavigationLink href={`${routes.tasksDetails.replace(":id", item.id)}`}>{item.id}</NavigationLink>,
    header: "Task ID",
    //minWidth: "100px",
    sortingField: "id",
  },
  {
    id: "taskSubject",
    cell: (item) => <NavigationLink href={routeParams.tasksDetails({ id: item.id })}>{item.subject}</NavigationLink>,
    header: "Task",
    //minWidth: "100px",
    sortingField: "subject",
  },
  {
    id: "relatedName",
    cell: (item) => (
      <NavigationLink
        href={(item.relatedType === "opportunity" && routeParams.opportunitiesDetails({ id: item.relatedId! })) || `${sfdcBaseUrl}/${item.relatedId ?? ""}`}
      >
        {item.relatedName}
      </NavigationLink>
    ),
    header: "Related to",
    //minWidth: "100px",
    //maxWidth: "200px",
    sortingField: "opportunityName",
  },
  {
    id: "partnerName",
    header: "Partner",
    cell: (item) => (
      <Link href={`https://discover.awspartner.com/?kw=${item.accountName}&type=allPartners`} target="_blank">
        {item.accountName}
      </Link>
    ),
    //minWidth: "100px",
    sortingField: "accountName",
  },
  {
    id: "taskStatus",
    header: "Status",
    cell: (item) => item.status,
    width: "130px",
    //minWidth: "100px",
    sortingField: "status",
  },
  {
    id: "dueDate",
    header: "Due Date",
    cell: (item) => item.activityDate,
    width: "130px",
    //minWidth: "100px",
    sortingField: "activityDate",
  },
  {
    id: "createdDate",
    header: "Created Date",
    cell: (item) => item.createdDate.substring(0, 10),
    //minWidth: "100px",
    sortingField: "createdDate",
  },
  {
    id: "lastModifiedDate",
    header: "Last Modified Date",
    cell: (item) => item.lastModifiedDate.substring(0, 10),
    //minWidth: "100px",
    sortingField: "lastModifiedDate",
  },
  {
    id: "bdActivityType",
    header: "BD Activity Type",
    cell: (item) => item.bdActivityType,
    //minWidth: "100px",
    sortingField: "bdActivityType",
  },
  {
    id: "description",
    header: "Comments",
    cell: (item) => item.description,
    //minWidth: "100px",
    sortingField: "description",
  },
  {
    id: "priority",
    header: "Priority",
    cell: (item) => item.priority,
    //minWidth: "100px",
    sortingField: "priority",
  },
  {
    id: "timeSpentHrs",
    header: "Time Spent (Hrs)",
    cell: (item) => item.timeSpentHrs,
    //minWidth: "100px",
    sortingField: "timeSpentHrs",
  },
  {
    id: "milestone",
    header: "Milestone",
    cell: (item) => item.milestone || "",
    //minWidth: "100px",
    sortingField: "milestone",
  },
  {
    id: "geo",
    header: "Geo",
    cell: (item) => item.geo,
    //minWidth: "100px",
    sortingField: "geo",
  },
  {
    id: "region",
    header: "Region",
    cell: (item) => item.region,
    //minWidth: "100px",
    sortingField: "region",
  },
  {
    id: "opportunityWorkstreamId",
    header: "Opportunity Workstream",
    cell: (item) => item.opportunityWorkstreamId,
    //minWidth: "100px",
    sortingField: "opportunityWorkstreamId",
  },
]

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Visible columns",
    options: VISIBLE_COLUMN_OPTIONS,
  },
]

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 Tasks" },
  { value: 30, label: "30 Tasks" },
  { value: 50, label: "50 Tasks" },
]
