import { Box, Button, Header, Link, Modal } from "@amzn/awsui-components-react"
import { useProfile } from "context/ProfileContext"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import routes, { routeParams } from "routes"

const ReleaseNoteModal: React.FC<{
  visible: boolean
}> = ({ visible }) => {
  const navigate = useNavigate()
  const { profileData, setNotesModal } = useProfile()

  const LAST_NOTES_KEY = "thunder.lastNotesSeen"
  const LAST_NOTES_DATE = "5-23-2024"
  const lastNotesSeen = localStorage.getItem(LAST_NOTES_KEY)

  useEffect(() => {
    if (lastNotesSeen != LAST_NOTES_DATE && profileData?.seeReleaseNotes == true) setNotesModal(true)
  }, [lastNotesSeen, profileData, setNotesModal])

  return (
    <Modal
      visible={visible}
      onDismiss={() => {}}
      header={<Header variant="h1">{`Thunder Release Notes for ${LAST_NOTES_DATE}`}</Header>}
      footer={
        <Box float="right">
          <Button
            variant="primary"
            onClick={() => {
              localStorage.setItem(LAST_NOTES_KEY, LAST_NOTES_DATE)
              navigate(routes.profile)
              setNotesModal(false)
            }}
          >
            Dismiss
          </Button>
        </Box>
      }
    >
      <div>
        {/* put patch notes here */}
        <h3>New Home Page - 2024 Goals Dashboard</h3>
        <p>
          The <a href={routes.goalsDashboard}>Goals Dashboard</a> is the new home page on Thunder. Easily manage your goals by reviewing your completed and
          pipeline activities and work towards the goal you have set for the year. You can drill down to view the opportunities/tasks associated with each goal
          to take further action. This page also provides more information about the goals.
        </p>
        <h3>Adding Activities</h3>
        <p>
          Adding new activity has become so much easier! We have minimized the number of inputs you&apos;ll need to make in order to create an activity. Simply
          select and activity, edit the pre-populated title, and add some description about the work completed. Thunder will take care of setting the rest of
          the values including BD Activity Type, SA Activity Type (if applicable), geo, region, due date, etc. Also, the Create Activity component is a modal,
          which means that you can avoid navigating back and forth between pages when creating multiple activities!
        </p>
        <h3>Customer Opportunities</h3>
        <p>
          View the top Customer Opportunities with your partner attached, sorted by descending $ARR. You will be able to view Utility and Committed Contract
          opportunities where your partner is attached. With the new goal of PSA Contributed Opportunities this year, this view will assist PSA&apos;s in
          determining which customer opportunities to be engaged.
        </p>
        <h3>Search Opportunities</h3>
        <p>
          Quickly search for opportunities to attach your activities to. You&apos;ll be able to narrow the search to opportunities owned by you, another person,
          or perform a free text search across all opportunities.
        </p>
        <h3>Design Win/Marquee Design Win {"(PO Opportunity)"}</h3>
        <p>
          For design win opportunities, you can now associate APN Solution using the solution picker, instead of pasting the S-ID manually. Your partner’s
          solutions will be available to choose from in the APN Solution Picker, so that you can easily associate your work to the Solution. You can also easily
          toggle to identify whether the design win is marquee, or if there is AWS Marketplace offer associated.
        </p>
        <h3>Updated List of Activities</h3>
        <p>
          List of activities have been updated and mapped to corresponding BD Activity Type category. When choosing an activity, users can easily understand
          which goal/KPI the activity will be counted towards.
        </p>
        <h3 style={{ color: "red" }}>*Requested Action*</h3>
        <p>Navigate to your profile page and update your settings as some options have changed!</p>
        <p>
          <b>Geo/Region</b> has been updated to reflect latest org structure. Please make sure to set it to the appropriate values.
          <br />
          <b>Selected Partners</b> drop down allows you to select the default partners you would like to be selected as a filter on the{" "}
          <a href={routes.customerOpportunitiesList}>Customer Opportunities</a> page. Choose the {"partners(s)"} you work with the most.
        </p>
        {/* put patch notes here ^^^ */}
      </div>
      <p>
        {"You can check our previous release notes on the "}
        <a target="_blank" rel="noopener noreferrer" href="https://w.amazon.com/bin/AWS/Teams/PartnerSA/Partner_Management/ProjectThunder/Releases">
          Thunder wiki
        </a>
        .
      </p>
    </Modal>
  )
}

export default ReleaseNoteModal
