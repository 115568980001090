export const DEFAULT_COLUMN_IDS = ["opportunityName", "partnerName", "stageName", "closeDate", "opportunityAmount"]

export const VISIBLE_COLUMN_OPTIONS = [
  { id: "id", label: "Opportunity ID" },
  { id: "opportunityName", label: "Opportunity" },
  { id: "partnerName", label: "Partner Name" },
  { id: "apnId", label: "APN ID" },
  { id: "accountName", label: "Customer Name" },
  { id: "stageName", label: "Stage Name" },
  { id: "opportunityAmount", label: "Opportunity Amount" },
  { id: "fiscalYear", label: "Fiscal Year" },
  { id: "closeDate", label: "Close Date" },
  { id: "lastModifiedDate", label: "Last Modified Date" },
  { id: "createdDate", label: "Created Date" },
  { id: "closedLostCategory", label: "Closed Lost Category" },
  { id: "closedLostReasonNotes", label: "Closed Lost Reason Notes" },
  { id: "nextStep", label: "Next Step" },
  { id: "description", label: "Description" },
  { id: "tasks", label: "Tasks" },
]

export const SEARCHABLE_COLUMNS = ["name", "accountName", "id", "stageName", "closeDate", "description"]
