import { BreadcrumbGroup } from "@amzn/awsui-components-react"
import MainLayout from "pmsa-polaris/components/MainLayout"
import { useNavigate } from "react-router-dom"
import { routeParams } from "routes"

import FormContent from "./FormContent"
import toolsTopics from "./tools-topics"

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
const CreateUpdateOpportunity = () => {
  const navigate = useNavigate()

  return (
    <MainLayout
      contentType="form"
      toolsTopics={toolsTopics}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Home", href: routeParams.home() },
            { text: "Create Opportunity", href: "#" },
          ]}
          onFollow={(e) => {
            e.preventDefault()
            navigate(e.detail.href)
          }}
        />
      }
    >
      <FormContent />
    </MainLayout>
  )
}

export default CreateUpdateOpportunity
