import { Opportunity } from "models/opportunity"

export type OpportunityRequest = Omit<
  Opportunity,
  | "lastModifiedDate"
  | "createdDate"
  | "id"
  | "name"
  | "hygiene"
  | "propensity"
  | "accountName"
  | "apnFocusedProgram"
  | "closedLostCategory"
  | "closedLostReasonNotes"
  | "tasks"
>

import request from "pmsa-polaris/api/request"

import { TaskResponse } from "./tasks"

export type OpportuniyResponse = Readonly<Opportunity>

const opportunitiesApi = {
  getAll: (expand?: boolean, alias?: string) =>
    request.get<OpportuniyResponse[]>("/opportunities", {
      params: typeof expand === "boolean" && {
        expand,
        alias,
      },
    }),

  get: (id: string) => request.get<OpportuniyResponse>(`/opportunities/${id}`),

  create: (params: OpportunityRequest) => request.post<OpportuniyResponse>("/opportunities", params),

  update: (id: string, params: OpportunityRequest) => request.put<OpportuniyResponse>(`/opportunities/${id}`, params),

  getTasks: (id: string) => request.get<TaskResponse[]>(`/opportunities/${id}/tasks`),

  search: (name: string) => request.get<OpportuniyResponse[]>(`/opportunities/search/${name}`),
}

export default opportunitiesApi
