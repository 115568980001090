import { ContentLayout, Header } from "@amzn/awsui-components-react"
import MainLayout from "pmsa-polaris/components/MainLayout"

import SearchContainer from "./SearchContainer"

const OpportunitySearch: React.FC = () => {
  return (
    <MainLayout>
      <ContentLayout
        header={
          <Header variant="h1" description="Search opportunities and directly attach a task or a goal.">
            Search Opportunities
          </Header>
        }
      >
        <SearchContainer />
      </ContentLayout>
    </MainLayout>
  )
}

export default OpportunitySearch
