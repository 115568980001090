import { Alert, Spinner } from "@amzn/awsui-components-react"
import { DetailsDisplay } from "components/DetailsDisplay"
import { CustomerOpportunity } from "models/customerOpportunity"
import moment from "moment"
import React from "react"

type Props = {
  opportunity?: CustomerOpportunity
  loading: boolean
  error: any
}

const CustomerDetailsSection: React.FC<Props> = ({ opportunity, loading, error }) => {
  const isLoading = loading || false

  moment().format("MM/DD/YYYY")

  return (
    (isLoading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (opportunity && (
      <DetailsDisplay
        copyToClipboard={true}
        details={[
          ["ID", opportunity?.id],
          ["Name", opportunity?.name],
          ["Customer Account Name", opportunity?.accountName],
          ["Partner Account Name", opportunity?.partnerAccountName],
          ["Description", opportunity?.description],
          ["Next Step", opportunity?.nextStep],
          ["Stage Name", opportunity?.stageName],
          // eslint-disable-next-line react/jsx-key
          ["Close Date", moment(opportunity?.closeDate).format("MM/DD/YYYY")],
          ["Owner", opportunity?.owner],
          [
            "Last Modified Date",
            // eslint-disable-next-line react/jsx-key
            moment(opportunity?.lastModifiedDate).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
          ],
          ["External Link", opportunity?.externalLink],
        ]}
      />
    )) ||
    (error && (
      <Alert visible={true} type="error" header="Failed to fetch details">
        Could not get details due to an error
      </Alert>
    )) ||
    null
  )
}

export default CustomerDetailsSection
