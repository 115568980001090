import "../../style.scss"

import { Icon, Popover } from "@amzn/awsui-components-react"
import Box from "@amzn/awsui-components-react/polaris/box"
import Link from "@amzn/awsui-components-react/polaris/link"
import { TableProps } from "@amzn/awsui-components-react/polaris/table"
import { OpportuniyResponse } from "api/opportunities"
import NavigationLink from "pmsa-polaris/components/NavigationLink"
import { Fragment } from "react"
import { routeParams } from "routes"

import { VISIBLE_COLUMN_OPTIONS } from "./columnsNames"

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<OpportuniyResponse>[] = [
  {
    id: "id",
    cell: (item) => (
      <Popover
        header={item.name}
        size="large"
        triggerType="text"
        content={
          <div>
            <h4 style={{ margin: "5px" }}>Description </h4>
            <p style={{ margin: "5px" }}>{item.description}</p>
            <h4 style={{ margin: "5px" }}>Next Steps </h4>
            <p style={{ margin: "5px" }}>
              {item.nextStep ? (item.nextStep.length > 128 ? item.nextStep.slice(0, 128).concat("...") : item.nextStep) : "No Next Step"}
            </p>
          </div>
        }
        renderWithPortal={true}
        dismissAriaLabel="Close"
      >
        {item.id}
      </Popover>
    ),
    header: "Opportunity ID",
    sortingField: "id",
  },
  {
    id: "opportunityName",
    cell: (item) => <NavigationLink href={routeParams.opportunitiesDetails({ id: item.id })}>{item.name}</NavigationLink>,
    header: "Opportunity",
    sortingField: "name",
  },
  {
    id: "opportunityTitle",
    cell: (item) => <NavigationLink href={routeParams.opportunitiesDetails({ id: item.id })}>{item.title}</NavigationLink>,
    header: "Title",
    sortingField: "title",
  },
  {
    id: "workstreamId",
    header: "Workstream",
    cell: (item) => item.workstreamId,
    sortingField: "workstreamId",
  },
  {
    id: "geo",
    header: "Geo",
    cell: (item) => item.geo,
    sortingField: "geo",
  },
  {
    id: "region",
    header: "Region",
    cell: (item) => item.region,
    sortingField: "region",
  },
  {
    id: "fiscalYear",
    header: "Fiscal Year",
    cell: (item) => item.fiscalYear,
    sortingField: "fiscalYear",
  },
  {
    id: "partnerName",
    header: "Partner",
    cell: (item) => (
      <Link href={`https://discover.awspartner.com/?kw=${item.accountName}&type=allPartners`} target="_blank" external>
        {item.accountName}
      </Link>
    ),
    sortingField: "accountName",
  },
  {
    id: "stageName",
    header: "Stage",
    cell: (item) => item.stageName,
    width: 125,
    sortingField: "stageName",
  },
  {
    id: "createdDate",
    header: "Created Date",
    cell: (item) => item.createdDate.substring(0, 10),
    sortingField: "createdDate",
  },
  {
    id: "lastModifiedDate",
    header: "Last Modified Date",
    cell: (item) => item.lastModifiedDate.substring(0, 10),
    sortingField: "lastModifiedDate",
  },
  {
    id: "closeDate",
    header: "Close Date",
    cell: (item) => item.closeDate,
    width: 130,
    sortingField: "closeDate",
  },
  {
    id: "closedLostCategory",
    header: "Closed Lost Category",
    cell: (item) => item.closedLostCategory,
    sortingField: "closedLostCategory",
  },
  {
    id: "closedLostReasonNotes",
    header: "Closed Lost Reason Notes",
    cell: (item) => item.closedLostReasonNotes,
    sortingField: "closedLostReasonNotes",
  },
  {
    id: "nextStep",
    header: "Next Step",
    cell: (item) => item.nextStep,
    sortingField: "nextStep",
  },
  {
    id: "description",
    header: "Description",
    cell: (item) => item.description,
    sortingField: "description",
  },
  {
    id: "vertical",
    header: "Vertical",
    cell: (item) => item?.vertical,
    sortingField: "description",
  },
  {
    id: "verticalDetails",
    header: "Vertical Details",
    cell: (item) => item?.verticalDetails?.toString(),
    sortingField: "description",
  },
  {
    id: "tasks",
    header: "Tasks (#)",
    cell: (item) => item.tasks.length,
    sortingField: "tasks",
  },
  {
    id: "propensity",
    header: "Propensity (#)",
    cell: (item) => (
      <Popover
        header={item.propensity && item.propensity.length > 0 ? "Propensity" : "No Propensity"}
        size="large"
        triggerType="text"
        content={
          <>
            {item.propensity &&
              item.propensity.map((propensity, index) => (
                <Fragment key={`p-${index}`}>
                  <Link href="https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Reportingv2#HSFDCHygieneIssues" target="_blank" external>
                    <span>◷ {propensity}</span>
                  </Link>
                  <br />
                </Fragment>
              ))}
          </>
        }
        renderWithPortal={true}
        dismissAriaLabel="Close"
      >
        <Box fontSize="body-m" data-testid="new-feature-announcement-trigger">
          <div
            className={
              item.propensityDays! <= 7 || item.propensity.find((a) => a.includes("SOP - Past Due")) // exception for SOP - Past Due to keep it red
                ? "status-error"
                : item.propensityDays! <= 14
                ? "status-medium"
                : item.propensityDays! <= 21
                ? "status-low"
                : "status-success"
            }
          >
            <Icon name={item.propensity && item.propensity.length > 0 ? "status-warning" : "status-positive"} />
            <span> {item.propensity ? item.propensity.length : 0}</span>
          </div>
        </Box>
      </Popover>
    ),
    width: 169,
    sortingField: "propensityDays",
  },
  {
    id: "hygiene",
    header: "Hygiene (#)",
    cell: (item) => (
      <Popover
        header={item.hygiene.length > 0 ? "Hygiene Issues" : "No Hygiene Issues"}
        size="large"
        triggerType="text"
        content={
          <>
            {item.hygiene.map((hygiene, index) => (
              <Fragment key={`h-${index}`}>
                <Link href="https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Reportingv2#HSFDCHygieneIssues" target="_blank" external>
                  <span>- {hygiene}</span>
                </Link>
                <br />
              </Fragment>
            ))}
          </>
        }
        renderWithPortal={true}
        dismissAriaLabel="Close"
      >
        <Box color="text-status-info" fontSize="body-m" data-testid="new-feature-announcement-trigger">
          <div className={item.hygiene.length > 0 ? "status-error" : "status-success"}>
            <Icon name={item.hygiene.length > 0 ? "status-negative" : "status-positive"} />
            <span> {item.hygiene.length}</span>
          </div>
        </Box>
      </Popover>
    ),
    width: 135,
    sortingField: "hygieneCount",
  },
]

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Visible columns",
    options: VISIBLE_COLUMN_OPTIONS,
  },
]

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 Opportunities" },
  { value: 30, label: "30 Opportunities" },
  { value: 50, label: "50 Opportunities" },
]
