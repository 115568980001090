import { SpaceBetween } from "@amzn/awsui-components-react"
import { CustomerOpportunity } from "models/customerOpportunity"
import { Task } from "models/task"

import ActivitiesTable from "./ActivitiesTable"

type Props = {
  opportunity?: CustomerOpportunity
  loading: boolean
  error: any
}

const AcitivitesContainer: React.FC<Props> = ({ opportunity, loading, error }) => {
  return (
    <SpaceBetween size="l">
      <ActivitiesTable tasks={opportunity?.tasks as Task[]} loading={loading} error={error} opportunity={opportunity} />
      <ActivitiesTable tasks={opportunity?.tasks as Task[]} loading={loading} error={error} showClosed />
    </SpaceBetween>
  )
}

export default AcitivitesContainer
