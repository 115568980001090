import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel"

const topics = {
  // List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
  helpSettings: (
    <HelpPanel header={<h2>Profile Settings</h2>}>
      <p>These are settings that you can change for Project Thunder.</p>
      <ul>
        <li>Geo - defines your Geo when creating SFDC tasks and opportunities</li>
        <li>Region - defines your Region when creating SFDC tasks and opportunities</li>
        <li>SFDC User Domain - sets your SFDC email domain if your SFDC account email differs from you corportate email.</li>
      </ul>
    </HelpPanel>
  ),
  helpSfdcUserDomain: (
    <HelpPanel header={<h2>SFDC User Domain</h2>}>
      <p>This field was created to address a small amount of users whose email domain in their SFDC username differs from Midway.</p>
      <p>
        You can check your SFDC username{" "}
        <a href="https://aws-crm.lightning.force.com/lightning/settings/personal/PersonalInformation/home" target="_blank" rel="noopener noreferrer">
          here
        </a>
        . If your {'"Username"'} field does not end in {'"@amazon.com'}, then you should edit this field to reflect the email domain of your SFDC username.
      </p>
      <p>
        For example, if you username field ended in {'"@amazon.co.uk"'}, then you would enter {'"amazon.co.uk"'} in this field.
      </p>
    </HelpPanel>
  ),
} as const

export default topics
