import { useCollection } from "@amzn/awsui-collection-hooks"
import { Button, Container, Pagination, SpaceBetween } from "@amzn/awsui-components-react"
import Header from "@amzn/awsui-components-react/polaris/header"
import Table from "@amzn/awsui-components-react/polaris/table"
import NewGoalModal from "components/NewGoalModal"
import { CustomerOpportunity } from "models/customerOpportunity"
import { Task, TASK_CLOSED_NAMES } from "models/task"
import React, { useMemo, useState } from "react"

import { COLUMN_DEFINITIONS } from "./tableConfig"

type Props = {
  showClosed?: boolean
  tasks?: Task[]
  loading: boolean
  error: any
  opportunity?: CustomerOpportunity
}

const ActivitiesTable: React.FC<Props> = ({ showClosed, tasks, loading, opportunity }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const filteredTasks = useMemo(
    () => (showClosed && tasks?.filter((task) => TASK_CLOSED_NAMES.has(task.status))) || tasks?.filter((task) => !TASK_CLOSED_NAMES.has(task.status)) || [],
    [tasks, showClosed]
  )

  const { items, collectionProps, paginationProps } = useCollection(filteredTasks, {
    pagination: {
      pageSize: 10,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "activityDate",
        },
        isDescending: false,
      },
    },
    selection: {},
  })

  return (
    <Container>
      <SpaceBetween size="l">
        <Header
          actions={
            !showClosed &&
            opportunity && (
              <Button variant="primary" onClick={(e) => setModalVisible(true)}>
                Attach Activity
              </Button>
            )
          }
        >
          {(showClosed && "Activity History") || "Open Activities"}
        </Header>
        <Table
          {...collectionProps}
          loading={loading}
          loadingText="Loading tasks"
          columnDefinitions={COLUMN_DEFINITIONS}
          items={items}
          pagination={<Pagination {...paginationProps} />}
        />
      </SpaceBetween>
      <NewGoalModal
        visible={modalVisible}
        setVisible={setModalVisible}
        relatedItem={opportunity || ({} as Readonly<CustomerOpportunity>)}
        relatedType="opportunity"
      />
    </Container>
  )
}

export default ActivitiesTable
