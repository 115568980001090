// react component for DetailsDisplay

import { Box, Button, ColumnLayout, Popover, SpaceBetween, StatusIndicator } from "@amzn/awsui-components-react"
import { chunk } from "lodash"
import React from "react"

type Props = {
  details: Array<Array<String | React.ReactElement<{ href: string }> | undefined>>
  copyToClipboard?: boolean
}

export const DetailsDisplay: React.FC<Props> = ({ details, copyToClipboard = false }) => {
  const columns = Math.ceil(Math.min(Math.max(details.length * (1 / 3), 1), 4))
  const detailsChunks = chunk(details, details.length / columns)

  return (
    <ColumnLayout columns={columns} variant="text-grid">
      {detailsChunks.map((detailChunk, i) => (
        <SpaceBetween key={`dchunk-${i}`} size="l">
          {detailChunk.map((detail, j) => (
            <div key={`detail-${j}`}>
              <Box variant="awsui-key-label">
                {detail[0]}
                {copyToClipboard && (
                  <Popover
                    size="small"
                    triggerType="custom"
                    dismissButton={false}
                    content={
                      <StatusIndicator key={`dstatus-${j}`} type="success">
                        Copied to clipboard!
                      </StatusIndicator>
                    }
                  >
                    <Button
                      variant="inline-icon"
                      iconName="copy"
                      onClick={() => {
                        if (React.isValidElement(detail[1]) && detail[1].props.href) {
                          navigator.clipboard.writeText(location.origin + detail[1].props.href)
                        }
                        if (typeof detail[1] === "string") {
                          navigator.clipboard.writeText(detail[1])
                        }
                      }}
                    />
                  </Popover>
                )}
              </Box>
              <div>{detail[1]}</div>
            </div>
          ))}
        </SpaceBetween>
      ))}
    </ColumnLayout>
  )
}
