import { Product } from "models/account"
import request from "pmsa-polaris/api/request"

export type ProductResponse = Readonly<Product>

const productsApi = {
  getAll: (ids: string) => request.get<ProductResponse[]>("/products", { params: { ids } }),
  get: (id: string) => request.get<ProductResponse[]>(`/products/${id}`),
}

export default productsApi
