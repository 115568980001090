import { Account, Product } from "models/account"
import request from "pmsa-polaris/api/request"

export type AccountResponse = Readonly<Account>
export type ProductResponse = Readonly<Product>

const accountsApi = {
  searchPartners: (name: string) => request.get<AccountResponse[]>(`/accounts/search/${name}`),
  searchAllAccounts: (name: string) => request.get<AccountResponse[]>(`/accounts/searchAll/${name}`),
  products: (id: string) => request.get<ProductResponse[]>(`/accounts/${id}/products`),
}

export default accountsApi
