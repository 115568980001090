import { PropertyFilterOperator, PropertyFilterToken, useCollection } from "@amzn/awsui-collection-hooks"
import {
  Box,
  Button,
  ButtonDropdown,
  Calendar,
  CollectionPreferences,
  CollectionPreferencesProps,
  ColumnLayout,
  DateInput,
  FormField,
  Header,
  Multiselect,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react"
import { OptionsLoadItemsDetail } from "@amzn/awsui-components-react/polaris/internal/components/dropdown/interfaces"
import { DropdownStatusProps } from "@amzn/awsui-components-react/polaris/internal/components/dropdown-status"
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import { NonCancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events"
import accountsApi from "api/accounts"
import customerOpportunityApi from "api/customerOpportunities"
import NewGoalModal from "components/NewGoalModal"
import { useCustomerOpportunityTaskContext } from "context/CustomerOpportunityContext"
import { CustomerOpportunity } from "models/customerOpportunity"
import EmptyState from "pmsa-polaris/components/EmptyState"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import useDebounce from "pmsa-polaris/hooks/useDebounce"
import usePersistentState from "pmsa-polaris/hooks/usePersistentState"
import usePromise from "pmsa-polaris/hooks/usePromise"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import { paginationLabels } from "pmsa-polaris/utils"
import { useCallback, useEffect, useState } from "react"
import routes, { routeParams } from "routes"

import { DEFAULT_COLUMN_IDS } from "./columnsNames"
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS, VISIBLE_CONTENT_OPTIONS } from "./tableConfig"

const { sfdcBaseUrl } = config

const CustomerOpportunitiesTable = () => {
  const [getOppListResponse, getOppsByPartnerId] = usePromise(customerOpportunityApi.getAll)

  const [accountAPNIds, setAccountAPNIds] = useState(new Set<string>())
  // manual loading
  const [manualLoading, setManualLoading] = useState(false)

  // context providers
  const { opportunityList: contextOpportunityList, getCustomerOpportunities } = useCustomerOpportunityTaskContext()
  const [fetchedOpps, setFetchedOpps] = useState([] as CustomerOpportunity[])
  const [opportunityList, setOpportunityList] = useState({
    loading: false,
    error: undefined,
    data: [],
  } as { loading: boolean; error: any; data: CustomerOpportunity[] | undefined })

  useEffect(() => {
    setManualLoading(getOppListResponse.loading)
  }, [getOppListResponse.loading])
  useEffect(() => {
    setManualLoading(contextOpportunityList.loading)
  }, [contextOpportunityList.loading])

  // error message
  const [getErrorMessage, setGetErrorMessage] = useState("Failed to load opportunities")

  useEffect(() => {
    if (opportunityList.error && opportunityList.error.response?.data?.errorCode == "USER_NOT_FOUND") {
      opportunityList.error.response?.data?.message && setGetErrorMessage(opportunityList.error.response.data.message)
    }
  }, [opportunityList.error])

  // goals modal
  const [modalVisible, setModalVisible] = useState(false)

  // partner filter
  const [partnerOption, setPartnerOption] = useState<OptionDefinition[]>([])
  const [partnerOptions, setPartnerOptions] = useState<OptionDefinition[]>([])
  const [multiSelectStatus, setMultiSelectStatus] = useState<DropdownStatusProps.StatusType>("pending")
  const debouncedAutoComplete = useDebounce(accountsApi.searchPartners, 200)

  const partnerChanged = useCallback(
    (options: OptionDefinition[]) => {
      if (options.length == 0)
        setOpportunityList((prevState) => ({
          ...prevState,
          data: fetchedOpps,
        }))
      else {
        const newAccounts = options.filter((option) => !accountAPNIds.has(option.description || "NOT_FOUND"))
        const newAccountIds = newAccounts.map((option) => option.value || "").filter((str) => str.trim() !== "")
        const newAPNIds = newAccounts.map((option) => option.description || "").filter((str) => str.trim() !== "")
        if (newAccountIds.length) {
          getOppsByPartnerId(undefined, newAccountIds)
          setAccountAPNIds(new Set([...accountAPNIds, ...newAPNIds]))
        }
        setOpportunityList((prevState) => ({
          ...prevState,
          data: fetchedOpps.filter((opp) => (options || []).some((option) => opp.apnId === option.description)),
        }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchedOpps, accountAPNIds]
  )

  const handleLoadItemsPartnerFilter: NonCancelableEventHandler<OptionsLoadItemsDetail> = ({ detail: { filteringText } }) => {
    const specialChars = "[/\\\\]"
    const checkSpecialCharsRegex = new RegExp(specialChars, "gi")

    if (filteringText && filteringText.length > 2) {
      const foundSpecialChars = filteringText.match(checkSpecialCharsRegex)
      const searchText = foundSpecialChars ? filteringText.split(checkSpecialCharsRegex)[0] : filteringText

      if (foundSpecialChars) {
        setPartnerOptions([
          {
            label: `Following special characters are not supported: "${foundSpecialChars.join("")}". Searching for: "${searchText}"`,
            disabled: true,
            iconName: "status-warning",
          },
        ])
        setMultiSelectStatus("loading")
      }

      if (filteringText && filteringText.length > 2) {
        const fetch = async () => {
          try {
            setMultiSelectStatus("loading")
            const accounts = (await debouncedAutoComplete(filteringText)).slice(0, 5)
            const newOptions: OptionDefinition[] = accounts.map((v) => ({
              value: v.id,
              label: v.name,
              description: v.spmsId as string,
            }))
            const options = [...newOptions, ...getPartnerOptions(false)]
            setPartnerOptions(options)
            setMultiSelectStatus("finished")
          } catch (e) {
            console.error(e)
            setMultiSelectStatus("error")
          }
        }
        fetch()
      }
    }
  }

  const getPartnerOptions = (initial: boolean): OptionDefinition[] => {
    const uniqueOptionDefinition: OptionDefinition[] = []
    const oppList = initial ? contextOpportunityList : opportunityList

    // get list of partners from opportunity list into temporary array
    oppList.data?.forEach((item) => {
      if (uniqueOptionDefinition.find((element) => element.description == item.apnId) == undefined) {
        uniqueOptionDefinition.push({ label: item.partnerAccountName, value: item.apnId, description: item.apnId })
      }
    })
    return uniqueOptionDefinition.sort((a, b) => (a.value && b.value ? a.value.toLowerCase().localeCompare(b.value.toLowerCase()) : 0))
  }

  useEffect(() => {
    if (!opportunityList.data || opportunityList.data?.length == 0) opportunityList.data = contextOpportunityList.data
    if (fetchedOpps.length == 0 && contextOpportunityList.data) setFetchedOpps([...contextOpportunityList.data])
    setPartnerOptions(getPartnerOptions(false))
    if (contextOpportunityList.data) setAccountAPNIds(new Set([...accountAPNIds, ...contextOpportunityList.data.map((opp) => opp.apnId || "")]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextOpportunityList.data])

  useEffect(() => {
    if (getOppListResponse.data) {
      const allOpps = [...fetchedOpps, ...getOppListResponse.data]
      setFetchedOpps(allOpps)
      setOpportunityList((prevState) => ({
        ...prevState,
        data: allOpps.filter((opp) => partnerOption.some((option) => opp.apnId === option.description)),
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOppListResponse])

  // table preferences
  const [preferences, setPreferences] = usePersistentState<CollectionPreferencesProps.Preferences>("thunder.customerOpportunitiesTablePreferences", {
    pageSize: 10,
    visibleContent: DEFAULT_COLUMN_IDS,
    wrapLines: false,
  })

  const dateForm = ({ value, onChange }: any) => (
    <div className="date-form">
      <FormField>
        <DateInput value={value ?? ""} onChange={(e) => onChange(e.detail.value ?? "")} placeholder="YYYY/MM/DD" />
      </FormField>
      <Calendar value={value ? value.replace("/", "-") : ""} onChange={(e) => onChange(e.detail.value ?? "")} locale="en-GB" />
    </div>
  )

  // table collection
  const { items, actions, collectionProps, propertyFilterProps, paginationProps } = useCollection(opportunityList.data || [], {
    propertyFiltering: {
      empty: (
        <EmptyState
          errorText={(opportunityList.error && getErrorMessage) || undefined}
          title="No opportunities"
          subtitle="No opportunities to display."
          action={<NavigationButton href={routes.opportunitiesCreate}>Create Opportunity</NavigationButton>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can't find a match."
          action={<Button onClick={() => actions.setPropertyFiltering({ operation: "and", tokens: [] })}>Clear filter</Button>}
        />
      ),
      filteringProperties: [
        {
          propertyLabel: "Close Date",
          groupValuesLabel: "Close Date Values",
          key: "closeDate",
          operators: (["=", "!=", "<", "<=", ">", ">="] as PropertyFilterOperator[]).map((operator) => ({
            operator,
            form: dateForm,
            match: (item, token) => {
              const [d1, d2] = [Date.parse(item as string), Date.parse(token)]
              switch (operator) {
                case "=":
                  return d1 === d2
                case "!=":
                  return d1 !== d2
                case "<":
                  return d1 < d2
                case "<=":
                  return d1 <= d2
                case ">":
                  return d1 > d2
                case ">=":
                  return d1 >= d2
                default:
                  return false
              }
            },
          })),
        },
        {
          propertyLabel: "Created Date",
          groupValuesLabel: "Created Date Values",
          key: "createdDate",
          operators: (["=", "!=", "<", "<=", ">", ">="] as PropertyFilterOperator[]).map((operator) => ({
            operator,
            form: dateForm,
            match: (item, token) => {
              const [d1, d2] = [Date.parse((item as string).substring(0, 10)), Date.parse(token.substring(0, 10))]
              switch (operator) {
                case "=":
                  return d1 === d2
                case "!=":
                  return d1 !== d2
                case "<":
                  return d1 < d2
                case "<=":
                  return d1 <= d2
                case ">":
                  return d1 > d2
                case ">=":
                  return d1 >= d2
                default:
                  return false
              }
            },
          })),
        },
        {
          propertyLabel: "Stage",
          groupValuesLabel: "Stage Values",
          key: "stageName",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Goal",
          groupValuesLabel: "Goal Values",
          key: "goals",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Opportunity ID",
          groupValuesLabel: "Opportunity ID Values",
          key: "id",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Opportunity Name",
          groupValuesLabel: "Opportunity Name Values",
          key: "name",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
      ],
    },
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "opportunityAmount",
        },
        isDescending: true,
      },
    },
    selection: {},
  })

  // table component
  const { selectedItems } = collectionProps
  return (
    <Box>
      <Table
        {...collectionProps}
        loading={manualLoading}
        loadingText="Loading opportunities"
        selectionType="multi"
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        visibleColumns={preferences.visibleContent}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        header={
          <Header
            variant="h2"
            counter={
              (opportunityList.data?.length &&
                (selectedItems ? `(${selectedItems.length}/${opportunityList.data?.length})` : `(${opportunityList.data?.length})`)) ||
              ""
            }
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <ButtonDropdown
                  items={[
                    {
                      id: "view-in-sfdc",
                      text: "View in SFDC",
                      disabled: selectedItems?.length == 0,
                      external: true,
                    },
                    {
                      id: "view-details",
                      text: "View Details",
                      disabled: selectedItems?.length == 0,
                    },
                  ]}
                  expandableGroups
                  onItemClick={(event) => {
                    switch (event.detail.id) {
                      case "view-in-sfdc": {
                        selectedItems?.forEach((item) => {
                          window.open(`${sfdcBaseUrl}/${item.id}`)
                        })
                        break
                      }
                      case "view-details": {
                        selectedItems?.forEach((item) => {
                          window.open(routeParams.customerOpportunityDetails({ id: item.id }))
                        })
                        break
                      }
                      default: {
                        break
                      }
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  variant="primary"
                  disabled={selectedItems?.length != 1}
                  onClick={() => {
                    setModalVisible(true)
                  }}
                >
                  Attach Activity
                </Button>
                {/* <NavigationButton variant="primary" href={routes.profile}>
                  Add Partners To Profile
                </NavigationButton> */}
                <Button
                  iconName="refresh"
                  variant="icon"
                  onClick={(e) => {
                    e.preventDefault()
                    setOpportunityList({
                      loading: false,
                      error: undefined,
                      data: [],
                    } as { loading: boolean; error: any; data: CustomerOpportunity[] | undefined })
                    setFetchedOpps([])
                    setPartnerOption([])
                    setAccountAPNIds(new Set<string>())
                    getCustomerOpportunities()
                  }}
                />
              </SpaceBetween>
            }
          >
            Customer Opportunities
          </Header>
        }
        filter={
          <SpaceBetween size="l">
            <ColumnLayout columns={2}>
              <PropertyFilter
                {...propertyFilterProps}
                i18nStrings={{
                  applyActionText: "Apply",
                  clearFiltersText: "Clear",
                  groupPropertiesText: "Properties",
                  groupValuesText: "Values",
                  operationAndText: "And",
                  operationOrText: "Or",
                  operatorsText: "Operators",
                  operatorText: "Operator",
                  propertyText: "Property",
                  valueText: "Value",
                }}
              />
              <Multiselect
                filteringType="manual"
                options={partnerOptions}
                selectedOptions={partnerOption}
                onChange={(event) => {
                  setPartnerOption([...event.detail.selectedOptions])
                  partnerChanged([...event.detail.selectedOptions])
                }}
                onLoadItems={handleLoadItemsPartnerFilter}
                placeholder="Select a partner"
                loadingText="Loading opportunities"
                statusType={opportunityList.loading ? "loading" : partnerOptions.length == 0 ? "error" : multiSelectStatus}
                errorText={partnerOptions.length == 0 ? "No Partners found" : "There was an error"}
              />
            </ColumnLayout>
          </SpaceBetween>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: "Page size",
              options: PAGE_SIZE_OPTIONS,
            }}
            wrapLinesPreference={{
              label: "Wrap lines",
              description: "Check to see all the text and wrap the lines",
            }}
            visibleContentPreference={{
              title: "Select visible columns",
              options: VISIBLE_CONTENT_OPTIONS,
            }}
          />
        }
      />
      <NewGoalModal
        visible={modalVisible}
        setVisible={setModalVisible}
        relatedItem={selectedItems?.length == 1 ? selectedItems[0] : ({} as CustomerOpportunity)}
        relatedType="opportunity"
      />
    </Box>
  )
}

export default CustomerOpportunitiesTable
