import { useCollection } from "@amzn/awsui-collection-hooks"
import { Button, Container, Pagination, SpaceBetween } from "@amzn/awsui-components-react"
import Header from "@amzn/awsui-components-react/polaris/header"
import Table from "@amzn/awsui-components-react/polaris/table"
import NewGoalModal from "components/NewGoalModal"
import { Opportunity } from "models/opportunity"
import { Task, TASK_CLOSED_NAMES } from "models/task"
import React, { useMemo, useState } from "react"

import { COLUMN_DEFINITIONS } from "./tableConfig"

type Props = {
  showClosed?: boolean
  tasks?: Task[]
  loading: boolean
  error: any
  opportunity?: Opportunity
}

const ActivitiesTable: React.FC<Props> = ({ showClosed, tasks, loading, opportunity }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const filteredTasks = useMemo(
    () => (showClosed && tasks?.filter((task) => TASK_CLOSED_NAMES.has(task.status))) || tasks?.filter((task) => !TASK_CLOSED_NAMES.has(task.status)) || [],
    [tasks, showClosed]
  )

  const { items, collectionProps, paginationProps } = useCollection(filteredTasks, {
    pagination: {
      pageSize: 10,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "activityDate",
        },
        isDescending: false,
      },
    },
    selection: {},
  })

  return (
    <Container>
      <SpaceBetween size="s">
        <Header
          actions={
            !showClosed &&
            opportunity && (
              // <ButtonDropdown
              //   variant="primary"
              //   items={[
              //     { id: "goal", text: "Goal" },
              //     {
              //       id: "workstream",
              //       text: "Workstream",
              //       href: `${routeParams.tasksCreate()}?${qs.stringify({
              //         relatedId: opportunity.id,
              //         relatedName: opportunity.name,
              //         opportunity: {
              //           geo: opportunity.geo,
              //           region: opportunity.region,
              //           workstreamName: opportunity.workstreamName,
              //         },
              //       })}`,
              //     },
              //   ]}
              //   onItemClick={(e) => {
              //     if (e.detail.id === "goal") setModalVisible(true)
              //   }}
              // >
              //   Attach
              // </ButtonDropdown>
              <Button variant="primary" onClick={(e) => setModalVisible(true)}>
                Attach Activity
              </Button>
            )
          }
        >
          {(showClosed && "Activity History") || "Open Activities"}
        </Header>
        <Table
          {...collectionProps}
          loading={loading}
          loadingText="Loading tasks"
          columnDefinitions={COLUMN_DEFINITIONS}
          items={items}
          pagination={<Pagination {...paginationProps} />}
        />
      </SpaceBetween>
      <NewGoalModal visible={modalVisible} setVisible={setModalVisible} relatedItem={opportunity || ({} as Readonly<Opportunity>)} relatedType="opportunity" />
    </Container>
  )
}

export default ActivitiesTable
