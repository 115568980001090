import { BreadcrumbGroup, Button, Container, ContentLayout, Header, SpaceBetween } from "@amzn/awsui-components-react"
import { useOpportunityTaskContext } from "context/OpportunityContext"
import { Task } from "models/task"
import MainLayout from "pmsa-polaris/components/MainLayout"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import qs from "qs"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import routes, { routeParams } from "routes"

import { DetailsSection } from "./DetailsSection"

const { sfdcBaseUrl } = config

const TaskDetails = () => {
  const { id: idParam } = useParams()
  const id = idParam!

  const navigate = useNavigate()

  const { taskList, getTask } = useOpportunityTaskContext()

  const [task, setTask] = useState<Task | undefined>(id && taskList.data ? taskList.data.find((task) => task.id === id) : undefined)
  const [taskError, setTaskError] = useState(false)

  useEffect(() => {
    if (!taskList.loading && !task && !taskError)
      getTask(id)
        .then((task) => setTask(task))
        .catch(() => setTaskError(true))
  }, [taskList, task, getTask, id, taskError])

  return (
    <MainLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Tasks", href: routeParams.tasksList() },
            { text: id, href: "" },
          ]}
          onFollow={(e) => {
            e.preventDefault()
            navigate(e.detail.href)
          }}
        />
      }
    >
      <ContentLayout header={<Header>Task Details</Header>}>
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="m">
                  <Button iconAlign="right" iconName="external" href={`${sfdcBaseUrl}/${id ?? ""}`} target="_blank">
                    View in SFDC
                  </Button>
                  <NavigationButton
                    disabled={taskList.loading}
                    href={`${routes.tasksCreate}?${qs.stringify({
                      ...task,
                      milestoneId: task?.milestone?.id ?? "",
                    })}`}
                  >
                    New Copy
                  </NavigationButton>
                  <NavigationButton variant="primary" iconAlign="right" iconName="edit" href={routeParams.tasksEdit({ id })}>
                    Edit
                  </NavigationButton>
                  <Button
                    iconName="refresh"
                    variant="icon"
                    onClick={(e) => {
                      e.preventDefault()
                      getTask(id)
                    }}
                  />
                </SpaceBetween>
              }
            >
              {task?.title || "Task"}
            </Header>
          }
        >
          <DetailsSection task={task} loading={taskList.loading} error={taskList.error} />
        </Container>
      </ContentLayout>
    </MainLayout>
  )
}

export default TaskDetails
