import { Multiselect, MultiselectProps, NonCancelableCustomEvent } from "@amzn/awsui-components-react" // Import MultiSelect component
import { Box, Button, Container, Form, FormField, Grid, Header, Link, Modal, SelectProps, SpaceBetween, Toggle } from "@amzn/awsui-components-react/polaris"
import { OptionsLoadItemsDetail } from "@amzn/awsui-components-react/polaris/internal/components/dropdown/interfaces"
import { DropdownStatusProps } from "@amzn/awsui-components-react/polaris/internal/components/dropdown-status"
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import { BaseNavigationDetail, NonCancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events"
import accountsApi from "api/accounts"
import { AxiosError } from "axios"
import GeoRegionSelector from "components/GeoRegionSelector"
import { useProfile } from "context/ProfileContext"
import { Formik, FormikHelpers, FormikProps } from "formik"
import { ProfileRequest } from "models/profile"
import ErrorFocus from "pmsa-polaris/components/ErrorFocus"
import { InputFormField } from "pmsa-polaris/components/FormFields"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import useDebounce from "pmsa-polaris/hooks/useDebounce"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import topics from "./tools-topics"

type ProfileFormValues = {
  profile: ProfileRequest
}

const DEFAULT_FORM_VALUES = {
  profile: {},
}

const FormContent: React.FC = () => {
  const pageTitle = "Profile"
  const setFlashMessages = useFlashbar()
  const { setContext } = useAppContext()
  const { id } = useParams<{
    id?: string
  }>()

  const debouncedAutoComplete = useDebounce(accountsApi.searchPartners, 200)

  const [sfdcUserDomainWarning, setSfdcUserDomainWarning] = useState(false)
  const [sfdcUserDomainEnabled, setSfdcUserDomainEnabled] = useState(false)
  const [selectedPartners, setSelectedPartners] = useState<readonly OptionDefinition[]>([])
  const [multiSelectStatus, setMultiSelectStatus] = useState<DropdownStatusProps.StatusType>("pending")
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [partnerOptions, setPartnerOptions] = useState<SelectProps.Options>([])

  const handleSelectedPartnersChange = (event: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>): void => {
    setSelectedPartners(event.detail.selectedOptions)
    setValuesState({
      profile: {
        ...valuesState.profile,
        selectedPartners: event.detail.selectedOptions.map((option) => ({
          label: option.label ?? "",
          value: option.value ?? "",
          description: option.description ?? "",
        })),
      },
    })
  }

  const handleLoadItems: NonCancelableEventHandler<OptionsLoadItemsDetail> = ({ detail: { filteringText } }) => {
    const specialChars = "[/\\\\]"
    const checkSpecialCharsRegex = new RegExp(specialChars, "gi")

    if (filteringText && filteringText.length > 2) {
      const foundSpecialChars = filteringText.match(checkSpecialCharsRegex)
      const searchText = foundSpecialChars ? filteringText.split(checkSpecialCharsRegex)[0] : filteringText

      if (foundSpecialChars) {
        setPartnerOptions([
          {
            label: `Following special characters are not supported: "${foundSpecialChars.join("")}". Searching for: "${searchText}"`,
            disabled: true,
            iconName: "status-warning",
          },
        ])
        setMultiSelectStatus("loading")
      }

      if (filteringText && filteringText.length > 2) {
        const fetch = async () => {
          try {
            setMultiSelectStatus("loading")
            const accounts = await debouncedAutoComplete(filteringText)
            const options: SelectProps.Option[] = accounts.map((v) => ({
              value: v.id,
              label: v.name,
              description: v.spmsId as string,
            }))
            setPartnerOptions(options)
            setMultiSelectStatus("finished")
          } catch (e) {
            console.error(e)
            setMultiSelectStatus("error")
          }
        }
        fetch()
      }
    }
  }

  // grab stuff from profile context
  const { getProfile, updateProfile, profileData, profileLoading, profileError, setNotesModal, darkMode, setDarkMode } = useProfile()

  // form values state, use default values initially
  const [valuesState, setValuesState] = useState<ProfileFormValues>({
    profile: DEFAULT_FORM_VALUES.profile,
  })

  // effect to set form values when profile data is loaded
  useEffect(() => {
    if (!isFormSubmitted) {
      setSelectedPartners(
        profileData?.selectedPartners ? profileData?.selectedPartners?.map(({ label, value, description }) => ({ label, value, description })) : []
      )
      setValuesState({ profile: { ...profileData } })
    }
    setIsFormSubmitted(false)
  }, [profileData, isFormSubmitted])

  // submit handler
  const handleSubmit = useCallback(
    async ({ profile: profile }: ProfileFormValues, { setSubmitting }: FormikHelpers<ProfileFormValues>) => {
      setSubmitting(true)

      updateProfile({ ...profile } as ProfileRequest)
        .then(() => {
          setFlashMessages([
            {
              content: "Profile updated successfully",
              presist: true,
              type: "success",
            },
          ])
        })
        .catch((error) => {
          const err = error as AxiosError<{
            statusCode: number
            message: string
          }>

          setFlashMessages([
            {
              type: "error",
              content: "There was an issue with updating profile.",
            },
          ])
        })
        .finally(() => {
          setSubmitting(false)
          setIsFormSubmitted(true)
        })
    },
    [setFlashMessages, updateProfile]
  )

  // info clicked handler, displays sidebar with extra info
  const handleInfoClicked = (toolsTopic: keyof typeof topics) => (e: CustomEvent<BaseNavigationDetail>) => {
    e.preventDefault()
    setContext({ toolsOpen: true, toolsTopic })
  }

  return (
    <Formik initialValues={valuesState} enableReinitialize onSubmit={handleSubmit} validateOnChange={false}>
      {({ handleSubmit, errors, values: { profile }, isSubmitting, setFieldValue }: FormikProps<ProfileFormValues>) => {
        return (
          <form onSubmit={handleSubmit} className={profileLoading ? "loading" : undefined}>
            <Form
              header={<Header variant="h1">{pageTitle}</Header>}
              actions={
                <Button variant="primary" formAction="submit" disabled={isSubmitting || profileLoading} loading={isSubmitting || profileLoading}>
                  Save Changes
                </Button>
              }
            >
              <Modal
                visible={sfdcUserDomainWarning}
                onDismiss={() => setSfdcUserDomainWarning(false)}
                header="Are you sure you want to continue?"
                footer={
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="l">
                      <Button variant="normal" onClick={() => setSfdcUserDomainWarning(false)}>
                        No
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setSfdcUserDomainEnabled(true)
                          setSfdcUserDomainWarning(false)
                        }}
                      >
                        Yes
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
              >
                <p>Do not edit this field unless you are experiencing issues.</p>
                <p>
                  Check your SFDC username{" "}
                  <a href="https://aws-crm.lightning.force.com/lightning/settings/personal/PersonalInformation/home" target="_blank" rel="noopener noreferrer">
                    here
                  </a>
                  . If it does not end in {'"@amazon.com"'}, then update this field with the whole domain name.
                </p>
                <p>
                  If in doubt, you can reach out to the Thunder dev team on Slack:{" "}
                  <a href="https://amzn-aws.slack.com/archives/C018FQV6ZQ9" target="_blank" rel="noopener noreferrer">
                    #ww-pmsa-insights-helpdesk
                  </a>
                </p>
              </Modal>
              <ErrorFocus />
              <SpaceBetween size="l">
                <Container
                  header={
                    <Header
                      info={
                        <Link variant="info" onFollow={handleInfoClicked("helpSettings")}>
                          Info
                        </Link>
                      }
                    >
                      Settings
                    </Header>
                  }
                >
                  <Grid
                    gridDefinition={[
                      { colspan: 12 }, // geo-region selector
                      { colspan: 8 }, // sfdc user domain
                      { colspan: 2 }, // sfdc user domain lock
                      { colspan: 3 }, // dark mode toggle
                      { colspan: 3 }, // show release notes toggle
                      { colspan: 12 }, // multiselect partners
                    ]}
                  >
                    <GeoRegionSelector save={!id} namePrefix="profile" />

                    <InputFormField
                      name="profile.sfdcUserDomain"
                      label="SFDC User Domain"
                      inputMode="text"
                      disabled={!sfdcUserDomainEnabled}
                      info={
                        <Link variant="info" onFollow={handleInfoClicked("helpSfdcUserDomain")}>
                          Info
                        </Link>
                      }
                      stretch={true}
                    />
                    <Box textAlign="center" padding={{ top: "xxl" }}>
                      <Toggle
                        checked={sfdcUserDomainEnabled}
                        onChange={(e) => {
                          if (sfdcUserDomainEnabled) setSfdcUserDomainEnabled(e.detail.checked)
                          setSfdcUserDomainWarning(e.detail.checked)
                        }}
                      >
                        Edit
                      </Toggle>
                    </Box>

                    <FormField label="Toggle Dark Mode">
                      <Toggle checked={darkMode} onChange={(e) => setDarkMode(e.detail.checked)}>
                        {darkMode ? "Enabled" : "Disabled"}
                      </Toggle>
                    </FormField>

                    <FormField
                      label="Show Release Notes"
                      info={
                        <Link
                          variant="info"
                          onFollow={(e) => {
                            e.preventDefault()
                            setNotesModal(true)
                          }}
                        >
                          Preview
                        </Link>
                      }
                    >
                      <Toggle
                        checked={valuesState.profile.seeReleaseNotes || false}
                        onChange={(e) => {
                          profile.seeReleaseNotes = e.detail.checked
                          setValuesState({
                            profile: {
                              ...valuesState.profile,
                              seeReleaseNotes: e.detail.checked,
                            },
                          })
                        }}
                      >
                        {valuesState.profile.seeReleaseNotes ? "Enabled" : "Disabled"}
                      </Toggle>
                    </FormField>
                    <FormField label="Selected Partners">
                      <Multiselect
                        filteringType="manual"
                        filteringPlaceholder="Find partners"
                        statusType={multiSelectStatus}
                        placeholder="Choose a partner to add to your profile"
                        loadingText="Loading partners"
                        errorText="Error fetching results."
                        recoveryText="Retry"
                        empty="No partners found"
                        options={partnerOptions}
                        selectedOptions={selectedPartners}
                        onChange={handleSelectedPartnersChange}
                        onLoadItems={handleLoadItems}
                      />
                    </FormField>
                  </Grid>
                </Container>
              </SpaceBetween>
            </Form>
          </form>
        )
      }}
    </Formik>
  )
}

export default FormContent
