const routes = {
  home: "/",
  customerOpportunitiesList: "/customerOpportunities",
  customerOpportunityDetails: "/customerOpportunities/:id",
  opportunitiesList: "/opportunities",
  opportunitiesCreate: "/opportunities/create",
  opportunitiesEdit: "/opportunities/:id/edit",
  opportunitiesDetails: "/opportunities/:id",
  opportunitiesTasks: "/opportunities/:opportunityId/tasks/",
  opportunitySearch: "/opportunitySearch",
  tasksList: "/tasks",
  tasksCreate: "/tasks/create",
  tasksEdit: "/tasks/:id/edit",
  tasksDetails: "/tasks/:id",
  profile: "/profile",
  goalsDashboard: "/goalsdashboard",
} as const

import { createRouteParams } from "pmsa-polaris/routeUtils"

export default routes
export const routeParams = createRouteParams(routes)
