import { Box, Button, Header, Link, Spinner, Table } from "@amzn/awsui-components-react"
import { useOpportunityTaskContext } from "context/OpportunityContext"
import NavigationLink from "pmsa-polaris/components/NavigationLink"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import qs from "qs"
import { routeParams } from "routes"

type props = {
  heading: string
  description?: string
  metrics: { label: string; value: string }[]
}

export const GoalsTable: React.FC = () => {
  const { setContext } = useAppContext()
  const { opportunityList, taskList, getTaskList: getTasks } = useOpportunityTaskContext()

  return (
    <div>
      <Table
        wrapLines={true}
        header={
          <Header variant="h1" actions={<Button variant="icon" iconName="refresh" onClick={() => getTasks()} />}>
            2024 PSA Goals
          </Header>
        }
        columnDefinitions={[
          {
            id: "goalName",
            header: "Goal",
            cell: (item) => <Box variant="h2">{item.goalName}</Box>,
          },
          {
            id: "goalDescription",
            header: "Description",
            cell: (item) => item.goalDescription,
          },
          {
            id: "goalCompleted",
            header: "Completed",
            cell: (item) =>
              taskList.loading ? (
                <Spinner size="big" />
              ) : (
                <NavigationLink href={item.goalCompletedUrl}>
                  <Box variant="awsui-value-large">{item.goalCompleted}</Box>
                </NavigationLink>
              ),
          },
          {
            id: "goalPipeline",
            header: "Pipeline",
            cell: (item) =>
              taskList.loading ? (
                <Spinner size="big" />
              ) : (
                <NavigationLink href={item.goalPipelineUrl}>
                  <Box variant="awsui-value-large">{item.goalPipeline}</Box>
                </NavigationLink>
              ),
          },
        ]}
        items={[
          {
            goalName: "Design Win",
            goalCategory: "Design Win",
            goalDescription: (
              <Box>
                A Design Win is defined as any solution or product that leverages AWS Services and infrastructure and can be repeatably sold to a customer to
                meet a key business challenge or outcome. More details{" "}
                <Link
                  onFollow={(e) => {
                    e.preventDefault
                    setContext({ toolsOpen: true, toolsTopic: "designWin" })
                  }}
                >
                  here
                </Link>
                .
              </Box>
            ),
            goalCompleted: opportunityList.data
              ? opportunityList.data.filter(
                  (item) => item.stageName === "Completed" && item.goals === "Design Win" && new Date(item.closeDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalPipeline: opportunityList.data
              ? opportunityList.data.filter(
                  (item) =>
                    !["Completed", "Closed Lost"].includes(item.stageName) &&
                    item.goals === "Design Win" &&
                    new Date(item.closeDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalCompletedUrl:
              routeParams.opportunitiesList() +
              `?${qs.stringify({
                filteringParams: { stageName: "Completed", goals: "Design Win", closeDateStart: "2024-01-01", closeDateEnd: "2024-12-31" },
              })}`,
            goalPipelineUrl:
              routeParams.opportunitiesList() +
              `?${qs.stringify({
                filteringParams: { "!stageName": "Completed,Closed Lost", goals: "Design Win", closeDateStart: "2024-01-01", closeDateEnd: "2024-12-31" },
              })}`,
          },
          {
            goalName: "Marquee Design Win",
            goalCategory: "Design Win",
            goalDescription: (
              <Box>
                A Marquee Design Win follows all of the above guidelines, but has an additional requirement of an external press release or customer reference.
                More details{" "}
                <Link
                  onFollow={(e) => {
                    e.preventDefault
                    setContext({ toolsOpen: true, toolsTopic: "marqueeDesignWin" })
                  }}
                >
                  here
                </Link>
                .
              </Box>
            ),
            goalCompleted: opportunityList.data
              ? opportunityList.data.filter(
                  (item) =>
                    item.stageName === "Completed" && item.goals === "Marquee Design Win" && new Date(item.closeDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalPipeline: opportunityList.data
              ? opportunityList.data.filter(
                  (item) =>
                    !["Completed", "Closed Lost"].includes(item.stageName) &&
                    item.goals === "Marquee Design Win" &&
                    new Date(item.closeDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalCompletedUrl:
              routeParams.opportunitiesList() +
              `?${qs.stringify({
                filteringParams: { stageName: "Completed", goals: "Marquee Design Win", closeDateStart: "2024-01-01", closeDateEnd: "2024-12-31" },
              })}`,
            goalPipelineUrl:
              routeParams.opportunitiesList() +
              `?${qs.stringify({
                filteringParams: {
                  "!stageName": "Completed,Closed Lost",
                  goals: "Marquee Design Win",
                  closeDateStart: "2024-01-01",
                  closeDateEnd: "2024-12-31",
                },
              })}`,
          },
          {
            goalName: "External Technical Content",
            goalDescription: (
              <Box>
                External Technical Content is any content that the SA has authored, created, or supported with a public URL. More details{" "}
                <Link
                  onFollow={(e) => {
                    e.preventDefault
                    setContext({ toolsOpen: true, toolsTopic: "externalTechnicalContent" })
                  }}
                >
                  here
                </Link>
                .
              </Box>
            ),
            goalCompleted: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status === "Completed" &&
                    item.bdActivityType === "PTR - External Technical Content" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalPipeline: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status !== "Completed" &&
                    item.status !== "Cancelled" && // add !disqualified
                    item.bdActivityType === "PTR - External Technical Content" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalCompletedUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  status: "Completed",
                  bdActivityType: "PTR - External Technical Content",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
            goalPipelineUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  "!status": "Completed,Cancelled",
                  bdActivityType: "PTR - External Technical Content",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
          },
          {
            goalName: "Internal Technical Content",
            goalDescription: (
              <Box>
                Internal Tech content can include things like PRFAQs, or enablement content created to educate internal AWS teams in Wisdom, High-spot,
                Knowledgemine, or Mindtickle. More details{" "}
                <Link
                  onFollow={(e) => {
                    e.preventDefault
                    setContext({ toolsOpen: true, toolsTopic: "internalTechnicalContent" })
                  }}
                >
                  here
                </Link>
                .
              </Box>
            ),
            goalCompleted: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status === "Completed" &&
                    item.bdActivityType === "PTR - Internal Technical Content" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalPipeline: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status !== "Completed" &&
                    item.status !== "Cancelled" &&
                    item.bdActivityType === "PTR - Internal Technical Content" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalCompletedUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  status: "Completed",
                  bdActivityType: "PTR - Internal Technical Content",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
            goalPipelineUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  "!status": "Completed,Cancelled",
                  bdActivityType: "PTR - Internal Technical Content",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
          },
          {
            goalName: "Public Speaking",
            goalDescription: (
              <Box>
                Public speaking involves presenting in an open-door setting to a general audience, not just to a specific customer or partner. More details{" "}
                <Link
                  onFollow={(e) => {
                    e.preventDefault()
                    setContext({ toolsOpen: true, toolsTopic: "publicSpeaking" })
                  }}
                >
                  here
                </Link>
                .
              </Box>
            ),
            goalCompleted: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status === "Completed" &&
                    item.bdActivityType === "PTR - Public Speaking" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalPipeline: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status !== "Completed" &&
                    item.status !== "Cancelled" &&
                    item.bdActivityType === "PTR - Public Speaking" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalCompletedUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  status: "Completed",
                  bdActivityType: "PTR - Public Speaking",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
            goalPipelineUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  "!status": "Completed,Cancelled",
                  bdActivityType: "PTR - Public Speaking",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
          },
          {
            goalName: "Service Beta",
            goalDescription: (
              <Box>
                To complete a Service Beta Activity the PSA must complete a demonstrable outcome with the AWS Partners partner team on an Eligible Service Beta
                feature or service. More details{" "}
                <Link
                  onFollow={(e) => {
                    e.preventDefault()
                    setContext({ toolsOpen: true, toolsTopic: "serviceBeta" })
                  }}
                >
                  here
                </Link>
                .
              </Box>
            ),
            goalCompleted: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status === "Completed" &&
                    item.bdActivityType === "PTR - Service Beta" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalPipeline: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status !== "Completed" &&
                    item.status !== "Cancelled" &&
                    item.bdActivityType === "PTR - Service Beta" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalCompletedUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  status: "Completed",
                  bdActivityType: "PTR - Service Beta",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
            goalPipelineUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  "!status": "Completed,Cancelled",
                  bdActivityType: "PTR - Service Beta",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
          },
          {
            goalName: "Strategic Enablement",
            goalDescription: (
              <Box>
                Strategic Enablement is defined as activities that support the practice development, understanding of, or deep skills in AWS. More details{" "}
                <Link
                  onFollow={(e) => {
                    e.preventDefault
                    setContext({ toolsOpen: true, toolsTopic: "strategicEnablement" })
                  }}
                >
                  here
                </Link>
                .
              </Box>
            ),
            goalCompleted: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status === "Completed" &&
                    item.bdActivityType === "PTR - Strategic Enablement" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalPipeline: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status !== "Completed" &&
                    item.status !== "Cancelled" &&
                    item.bdActivityType === "PTR - Strategic Enablement" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalCompletedUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  status: "Completed",
                  bdActivityType: "PTR - Strategic Enablement",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
            goalPipelineUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  "!status": "Completed,Cancelled",
                  bdActivityType: "PTR - Strategic Enablement",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
          },
          {
            goalName: "Technical Validations",
            goalDescription: (
              <Box>
                Technical Validations require working with a Partner to deep dive into a design and generate a written document on the full
                design/implementation. More details{" "}
                <Link
                  onFollow={(e) => {
                    e.preventDefault
                    setContext({ toolsOpen: true, toolsTopic: "technicalValidation" })
                  }}
                >
                  here
                </Link>
                .
              </Box>
            ),
            goalCompleted: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status === "Completed" &&
                    item.bdActivityType === "PTR - Technical Validations" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalPipeline: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status !== "Completed" &&
                    item.status !== "Cancelled" &&
                    item.bdActivityType === "PTR - Technical Validations" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalCompletedUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  status: "Completed",
                  bdActivityType: "PTR - Technical Validations",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
            goalPipelineUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  "!status": "Completed,Cancelled",
                  bdActivityType: "PTR - Technical Validations",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
          },
          {
            goalName: "Partner Program Technical Validations",
            goalDescription: (
              <Box>
                Partner Program Technical Validations include all design reviews conducted for Partner Programs such as Competency, Service Delivery Program &
                Service Ready, etc. More details{" "}
                <Link
                  onFollow={(e) => {
                    e.preventDefault
                    setContext({ toolsOpen: true, toolsTopic: "partnerProgramTechnicalValidation" })
                  }}
                >
                  here
                </Link>
                .
              </Box>
            ),
            goalCompleted: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status === "Completed" &&
                    item.bdActivityType === "PTR - Partner Programs Technical Validations" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalPipeline: taskList.data
              ? taskList.data.filter(
                  (item) =>
                    item.status !== "Completed" &&
                    item.status !== "Cancelled" &&
                    item.bdActivityType === "PTR - Partner Programs Technical Validations" &&
                    new Date(item.activityDate).getFullYear() === new Date().getFullYear()
                ).length
              : "0",
            goalCompletedUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  status: "Completed",
                  bdActivityType: "PTR - Partner Programs Technical Validations",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
            goalPipelineUrl:
              routeParams.tasksList() +
              `?${qs.stringify({
                filteringParams: {
                  "!status": "Completed,Cancelled",
                  bdActivityType: "PTR - Partner Programs Technical Validations",
                  activityDateStart: "2024/01/01",
                  activityDateEnd: "2024/12/31",
                },
              })}`,
          },
        ]}
      />
    </div>
  )
}
