import { Button, Container, Form, Header, Link, SpaceBetween } from "@amzn/awsui-components-react/polaris"
import { BaseNavigationDetail } from "@amzn/awsui-components-react/polaris/internal/events"
import { TaskRequest } from "api/tasks"
import { AxiosError } from "axios"
import { useOpportunityTaskContext } from "context/OpportunityContext"
import { useProfile } from "context/ProfileContext"
import { useWorkstreamCatalog } from "context/WorkstreamCatalogContext"
import { Formik, FormikHelpers, FormikProps } from "formik"
import SA_ACTIVITY_MAPPING from "models/activityMapping"
import { OmitWorkstreamId } from "models/shared"
import { TASK_PRIORITY, TASK_STATUS, TASK_TYPE } from "models/task"
import moment from "moment"
import ErrorFocus from "pmsa-polaris/components/ErrorFocus"
import LoadingHeader from "pmsa-polaris/components/LoadingHeader"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { routeParams } from "routes"
import * as Yup from "yup"

import FieldContent from "./FieldContent"
import topics from "./tools-topics"

const { sfdcBaseUrl } = config

export type TaskFormValues = Omit<OmitWorkstreamId<TaskRequest>, "milestone"> & {
  milestoneId: string
}

export const DEFAULT_TASK: TaskFormValues = {
  activityDate: moment().format("YYYY-MM-DD"),
  bdActivityType: "",
  description: "",
  geo: "",
  milestoneId: "100",
  priority: TASK_PRIORITY[0],
  region: "",
  relatedId: "",
  relatedType: "opportunity",
  saActivity: "",
  status: TASK_STATUS[0],
  timeSpentHrs: "0",
  title: "",
  type: TASK_TYPE[0],
  workstreamName: "",
}

const ERROR_TEXT = {
  serverError: "We can't process the request right now because of an issue with the server. Try again later.  If the problem persists, contact support",
  taskError: "There was an issue with creating task.",
  opportunityError: "There was an issue with creating opportunity.",
}

export const getValidaionSchema = (isTaskCreate: boolean) =>
  Yup.object().shape({
    workstreamName: Yup.string().required("You must specify workstream function."),
    title: Yup.string()
      .required("You must specify title.")
      .max(90, "Task subject can't be more than 90 characters.")
      .matches(/^[^\\]*$/, "Backslash is not allowed.")
      .when(["workstreamId"], {
        is: (workstreamId: string) => workstreamId === "ETC-10",
        then: (schema) =>
          schema.matches(/Content Security Review \(CSR\) - [A-Z][0-9]{9,10}/g, "Title must in format 'Content Security Review (CSR) - <SIM Ticket Id>'"),
      }),
    //bdActivityType: Yup.string().required("You must specify bd activity type."),
    timeSpentHrs: Yup.number()
      .typeError("You must specify a valid number")
      .positive("Time spent must be a positive number")
      .min(0, "Time spent must be greater than or equal to 0")
      .max(1000, "Time spent must be less than 1000")
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .test("decimaldigits", "You can specify a number up to 1 decimal place", (number) => (number ? Number.isInteger(number * 10) : true)),
    activityDate: Yup.date()
      .required("You must specify due date")
      .typeError("You must specify date in the format YYYY/MM/DD")
      .min("2020/01/01", "You must specify date greater than 2020/01/01")
      .max(moment().add(1, "year"), "You must specify a task within 1 year from today"),
    description: Yup.string()
      .max(32000, "Description can't be more than 32000 characters.")
      .matches(/^[^\\]*$/, "Backslash is not allowed.")
      .when(["bdActivityType", "workstreamId", "status"], {
        is: (bdActivityType: string, workstreamId: string, status: string) =>
          bdActivityType === "PTR - External Technical Content" && workstreamId !== "ETC-10" && status !== "In Progress",
        then: (schema) =>
          schema
            .required()
            .matches(
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g,
              "Must provide a public URL! For example: https://aws.amazon.com"
            ),
      }),
    status: Yup.string().required("You must specify status."),
    priority: Yup.string().required("You must specify priority."),
    milestoneId: Yup.string().required("You must specify a milestone."),
    ...(isTaskCreate && {
      relatedId: Yup.string().required("You must specify a related entity"),
      geo: Yup.string().required("You must specify geo"),
      region: Yup.string().required("You must specify region"),
    }),
    type: Yup.string().required("You must specify task type"),
  })

const FormContent: React.FC = () => {
  const { id } = useParams<{
    id: string
  }>()

  const queryParams = useQueryString()
  const { opportunity: opportunityQs = {}, ...searchParamsObject } = queryParams

  const { profileData, profileLoading, profileError } = useProfile()
  const { workstreamsLoading, streamsByName, geoOptions } = useWorkstreamCatalog()
  const { taskList, getTask, createTask, updateTask } = useOpportunityTaskContext()

  const isLoading = taskList.loading || workstreamsLoading || profileLoading

  const setFlashMessages = useFlashbar()

  const task = id && taskList.data ? taskList.data.find((task) => task.id === id) : undefined

  const opportunityData = useMemo(() => {
    if (opportunityQs) {
      const { workstreamName, geo, region } = opportunityQs
      if (workstreamName && geo && region) {
        return {
          workstreamName,
          geo,
          region,
        }
      }
    }
    return undefined
  }, [opportunityQs])

  const [initialValues, setInitialValues] = useState<TaskFormValues>(() => {
    const defaultKeys = new Set(Object.keys(DEFAULT_TASK))

    Object.keys(searchParamsObject).forEach((key) => {
      if (!defaultKeys.has(key)) {
        delete searchParamsObject[key]
      }
    })

    const values = {
      ...DEFAULT_TASK,
      ...searchParamsObject,
      title: queryParams.subject ? queryParams.subject.split("-").slice(6).join("-") : "",
      workstreamId: queryParams.subject ? queryParams.subject.split(/[-@]/).slice(4, 6).join("-") : "",
    }

    if (!values.geo && opportunityQs.geo) {
      values.geo = opportunityQs.geo
    }
    if (!values.region && opportunityQs.region) {
      values.region = opportunityQs.region
    }

    console.log("queryParams", queryParams)
    console.log("values", values)

    return values
  })

  const navigate = useNavigate()
  const { setContext } = useAppContext()

  const handleInfoClicked = (toolsTopic: keyof typeof topics) => (e: CustomEvent<BaseNavigationDetail>) => {
    e.preventDefault()
    setContext({ toolsOpen: true, toolsTopic })
  }

  useEffect(() => {
    if (id && task)
      setInitialValues((state) => {
        const workstreamId = task?.workstreamId ? task?.workstreamId : task?.subject.split(/[-|@]/g).slice(4, 6).join("-")

        let workstreamName = ""
        Object.entries(SA_ACTIVITY_MAPPING).find(([key, array]) => (workstreamName = array.find((item) => item.code === workstreamId)?.activityName || ""))

        return {
          ...state,
          ...task,
          title: task?.title || task?.subject.split("-").slice(6).join("-") || "",
          workstreamId: workstreamId,
          workstreamName: workstreamName || "",
        }
      })
    else if (id) {
      getTask(id)
    } else
      setInitialValues((prevState) => ({
        ...prevState,
        geo: profileData?.geo ?? "",
        region: profileData?.region ?? "",
      }))
  }, [geoOptions, getTask, id, profileData, task])

  const handleSubmit = useCallback(
    async ({ workstreamName, milestoneId, relatedId: opportunityId, ...task }: TaskFormValues, { setSubmitting }: FormikHelpers<TaskFormValues>) => {
      try {
        const taskRequest: TaskRequest = {
          ...task,
          milestone: {
            id: "100",
            name: "Execute",
          },
          relatedId: opportunityId!,
        }

        if (id) {
          const updatedTask = await getTask((await updateTask(id, taskRequest)).id)

          setFlashMessages([
            {
              content: "Task updated successfully",
              presist: true,
              type: "success",
            },
          ])
          navigate(
            routeParams.tasksDetails({
              id: updatedTask.id,
            })
          )
        } else {
          const newTask = await getTask((await createTask(taskRequest)).id)

          setFlashMessages([
            {
              content: "Task created successfully",
              presist: true,
              type: "success",
            },
          ])

          navigate(
            routeParams.tasksDetails({
              id: newTask.id,
            })
          )
        }
      } catch (error) {
        console.error(error)

        const err = error as AxiosError<{ statusCode: number; message: string }>
        if (err.response && err.response.data && err.response.data.statusCode == 403 && err.response.data.message == "user does not own record") {
          sfdcBaseUrl

          const errmsg = `Only records that you own can be updated.  If you need to change the opportunity owner, navigate to ${sfdcBaseUrl}/${id}`
          setFlashMessages([
            {
              type: "error",
              content: errmsg,
            },
          ])
        } else {
          setFlashMessages([
            {
              type: "error",
              content: ERROR_TEXT.taskError,
            },
          ])
        }
      } finally {
        setSubmitting(false)
      }
    },
    [createTask, id, navigate, setFlashMessages, updateTask, getTask]
  )

  const validationSchema = useMemo(() => getValidaionSchema(!id), [id])

  const pageTitle = id ? "Update Task" : "Create Task"

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit} validateOnChange={false} validationSchema={validationSchema}>
      {({ handleSubmit, errors, values: taskFormValues, isSubmitting, setFieldValue }: FormikProps<TaskFormValues>) => (
        <form onSubmit={handleSubmit} className={isLoading ? "loading" : undefined}>
          <Form
            header={<LoadingHeader loading={isLoading}>{pageTitle}</LoadingHeader>}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <NavigationButton
                  variant="link"
                  href={
                    (id &&
                      routeParams.tasksDetails({
                        id: id,
                      })) ||
                    routeParams.home()
                  }
                  disabled={isSubmitting || isLoading}
                >
                  Cancel
                </NavigationButton>
                <Button variant="primary" formAction="submit" disabled={isSubmitting || isLoading} loading={isSubmitting || isLoading}>
                  {pageTitle}
                </Button>
              </SpaceBetween>
            }
            errorText={taskList.error ? ERROR_TEXT.serverError : ""}
          >
            <ErrorFocus />
            <Container
              header={
                <Header
                  info={
                    <Link variant="info" onFollow={handleInfoClicked("main")}>
                      Info
                    </Link>
                  }
                >
                  Task (Activity)
                </Header>
              }
            >
              <FieldContent
                taskId={id}
                opportunityData={opportunityData}
                onInfoClicked={handleInfoClicked}
                relatedType={task?.relatedType ?? "opportunity"}
                relatedToInitialOption={
                  task
                    ? {
                        label: task?.relatedName,
                        value: task?.relatedId,
                      }
                    : searchParamsObject.relatedName && searchParamsObject.relatedId
                    ? {
                        label: searchParamsObject.relatedName,
                        value: searchParamsObject.relatedId,
                      }
                    : undefined
                }
              />
            </Container>
          </Form>
        </form>
      )}
    </Formik>
  )
}

export default FormContent
