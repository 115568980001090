import { Box, Button, Modal } from "@amzn/awsui-components-react"
import { useProfile } from "context/ProfileContext"
import { useNavigate } from "react-router-dom"
import { routeParams } from "routes"

const NewUserModal: React.FC<{ visible: boolean }> = ({ visible }) => {
  const navigate = useNavigate()

  const { setNewUserModal } = useProfile()

  return (
    <Modal
      visible={visible}
      // onDismiss={() => setNewUserModal(false)}
      header={"Welcome to Thunder 2.0!"}
      footer={
        <Box float="right">
          <Button
            variant="primary"
            onClick={() => {
              setNewUserModal(false)
              navigate(routeParams.profile())
            }}
          >
            Go to profile page
          </Button>
        </Box>
      }
    >
      <p>{"It looks like you're a new user or you haven't used the tool in a while."}</p>
      <p>{"One time setup is required. Please go to your profile page and set any required fields."}</p>
      <p>{"Thunder will not work properly if you skip this step!"}</p>
    </Modal>
  )
}

export default NewUserModal
