import "@amzn/awsui-global-styles/polaris.css"

import Spinner from "@amzn/awsui-components-react/polaris/spinner"
import { CognitoUser } from "amazon-cognito-identity-js"
import { Analytics, Auth } from "aws-amplify"
import SideNavigationBar from "components/SideNavigationBar"
import TopNavigationBar from "components/TopNavigationBar"
import CustomerOpportunitiesContextProvider from "context/CustomerOpportunityContext"
import OpportunityContextProvider from "context/OpportunityContext"
import ProfileContextProvider from "context/ProfileContext"
import WorkstreamCatalogContextProvider from "context/WorkstreamCatalogContext"
import CreateUpdateOpportunity from "pages/CreateUpdateOpportunity"
import CreateUpdateTask from "pages/CreateUpdateTask"
import CustomerOpportunities from "pages/CustomerOpportunities"
import CustomerDetailsSection from "pages/CustomerOpportunityDetails"
import GoalsDashboard from "pages/GoalsDashboard"
import Opportunities from "pages/Opportunities"
import DetailsSection from "pages/OpportunityDetails"
import OpportunitySearch from "pages/OpportunitySearch"
import Profile from "pages/ProfilePage"
import TaskDetails from "pages/TaskDetails"
import Tasks from "pages/Tasks"
import { onTokenExpired } from "pmsa-polaris/api/request"
import MainApp from "pmsa-polaris/components/MainApp"
import config from "pmsa-polaris/config"
import jwt from "pmsa-polaris/jwt"
import { useEffect, useState } from "react"
import { Route } from "react-router-dom"

import routes from "./routes"

onTokenExpired(async () => {
  const user = await Auth.currentAuthenticatedUser()
  jwt.token = user.getSignInUserSession()?.getIdToken().getJwtToken()!
})

const combineComponents = (...components: React.FC[]): React.FC => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }: React.ComponentProps<React.FC>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => <>{children}</>
  )
}

const providers = [WorkstreamCatalogContextProvider, ProfileContextProvider, OpportunityContextProvider, CustomerOpportunitiesContextProvider]

const App = () => {
  const [error, setError] = useState(false)
  const [user, setUser] = useState<CognitoUser | null>(null)
  const [loading, setLoading] = useState(true)

  document.body.classList.add("awsui-visual-refresh")

  useEffect(() => {
    const configureAmplify = async () => {
      const amplifyConfig = await import("./amplifyConfig")
      Auth.configure(amplifyConfig.default)

      const { pinpointId } = config

      const analyticsConfig = {
        AWSPinpoint: {
          // Amazon Pinpoint App Client ID
          appId: pinpointId,
          // Amazon service region
          region: "us-west-2",
          mandatorySignIn: false,
        },
      }

      Analytics.configure(analyticsConfig)
    }

    ;(async () => {
      try {
        await configureAmplify()

        try {
          const user: CognitoUser = await Auth.currentAuthenticatedUser()
          jwt.token = user.getSignInUserSession()?.getIdToken().getJwtToken()!
          setUser(user)
        } catch (loginError) {
          await Auth.federatedSignIn({ provider: "AmazonFederate" } as any)
        }
      } catch (error) {
        console.error(error)
        setError(true)
      }
      setLoading(false)
    })()
  }, [])

  return (
    (loading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (user && (
      <MainApp container={combineComponents(...providers)} serviceNavigation={<SideNavigationBar />} topNavigation={<TopNavigationBar />}>
        <Route path={routes.home} element={<GoalsDashboard />} />
        <Route path={routes.opportunitiesList} element={<Opportunities />} />
        <Route path={routes.customerOpportunitiesList} element={<CustomerOpportunities />} />
        <Route path={routes.customerOpportunityDetails} element={<CustomerDetailsSection />} />
        <Route path={routes.opportunitiesCreate} element={<CreateUpdateOpportunity />} />
        <Route path={routes.opportunitiesEdit} element={<CreateUpdateOpportunity />} />
        <Route path={routes.opportunitiesDetails} element={<DetailsSection />} />
        <Route path={routes.opportunitySearch} element={<OpportunitySearch />} />
        <Route path={routes.tasksList} element={<Tasks />} />
        <Route path={routes.tasksDetails} element={<TaskDetails />} />
        <Route path={routes.tasksCreate} element={<CreateUpdateTask />} />
        <Route path={routes.tasksEdit} element={<CreateUpdateTask />} />
        <Route path={routes.profile} element={<Profile />} />
        <Route path={routes.goalsDashboard} element={<GoalsDashboard />} />
      </MainApp>
    ))
  )
}

export default App
