export const DEFAULT_COLUMN_IDS = ["taskSubject", "opportunityName", "partnerName", "taskStatus", "dueDate"]

export const VISIBLE_COLUMN_OPTIONS = [
  { id: "taskSubject", label: "Task" },
  { id: "relatedName", label: "Opportunity" },
  { id: "partnerName", label: "Partner" },
  { id: "taskStatus", label: "Status" },
  { id: "dueDate", label: "Due Date" },
  { id: "bdActivityType", label: "BD Activity Type" },
  { id: "createdDate", label: "Created Date" },
  { id: "description", label: "Description" },
  { id: "id", label: "Task ID" },
  { id: "lastModifiedDate", label: "Last Modified Date" },
  { id: "priority", label: "Priority" },
  { id: "timeSpentHrs", label: "Time Spent (Hrs)" },
  { id: "milestone", label: "Milestone" },
  { id: "geo", label: "Geo" },
  { id: "region", label: "Region" },
  { id: "opportunityWorkstreamId", label: "Opportunity Workstream" },
]

export const SEARCHABLE_COLUMNS = ["id", "subject", "status", "accountName", "relatedName", "relatedId"]
