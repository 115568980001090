import { Box, Button, Container, SpaceBetween } from "@amzn/awsui-components-react"
import NewGoalModal from "components/NewGoalModal"
import RelatedToPicker from "components/RelatedToPicker"
import { CustomerOpportunity } from "models/customerOpportunity"
import { Opportunity } from "models/opportunity"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const SearchContainer: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [relatedType, setRelatedType] = useState<string | undefined>(undefined)
  const [relatedItem, setRealtedItem] = useState<Opportunity | CustomerOpportunity | undefined>(undefined)

  const navigate = useNavigate()

  return (
    <Container
      footer={
        <Box float="right">
          <SpaceBetween size="s" direction="horizontal">
            <Button
              variant="primary"
              disabled={!relatedType && !relatedItem}
              onClick={() => {
                setModalVisible(true)
              }}
            >
              Attach Activity
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <RelatedToPicker
        onSelected={(type, item) => {
          setRelatedType(type)
          setRealtedItem(item)
        }}
      />

      <NewGoalModal
        visible={modalVisible}
        setVisible={setModalVisible}
        relatedType={relatedType || "opportunity"}
        relatedItem={relatedItem || ({} as Opportunity)}
      />
    </Container>
  )
}

export default SearchContainer
