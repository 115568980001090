import profilesApi, { ProfileData, ProfileResponse } from "api/profiles"
import NewUserModal from "components/NewUserModal"
import ReleaseNoteModal from "components/ReleaseNotesModal"
import usePromise from "pmsa-polaris/hooks/usePromise"
import React, { useContext, useEffect, useMemo, useState } from "react"

const DEFAULT_CONTEXT: {
  profileData: ProfileResponse | undefined
  profileLoading: boolean
  profileError: any
  getProfile: () => Promise<ProfileResponse | undefined>
  updateProfile: (params: ProfileData) => Promise<ProfileResponse | undefined>
  darkMode: boolean
  setDarkMode: (value: React.SetStateAction<boolean>) => void
  setNotesModal: (value: React.SetStateAction<boolean>) => void
  setNewUserModal: (value: React.SetStateAction<boolean>) => void
} = {
  profileData: undefined,
  profileError: null,
  profileLoading: false,
  getProfile: () => {
    return Promise.resolve(undefined)
  },
  updateProfile: () => {
    return Promise.resolve(undefined)
  },
  darkMode: true,
  setDarkMode: () => {},
  setNotesModal: () => {},
  setNewUserModal: () => {},
}

const ProfileContext = React.createContext(DEFAULT_CONTEXT)

const ProfileContextProvider: React.FC = ({ children }) => {
  // profile API calls
  const [{ loading: getProfileLoading, error: getProfileError, data: getProfileData }, getProfile] = usePromise(profilesApi.get)
  const [{ loading: updateProfileLoading, error: updateProfileError, data: updateProfileData }, updateProfile] = usePromise(profilesApi.update)

  // modal states
  const [newUserModal, setNewUserModal] = useState(false)
  const [notesModal, setNotesModal] = useState(false)

  // error state
  const [status, setStatus] = useState("")

  // effect to get profile on mount
  useEffect(() => {
    if (status !== "error") {
      getProfile()
        .then((profile) => {
          // set default profile settings
          if (profile.seeReleaseNotes == undefined) {
            updateProfile({ ...profile, seeReleaseNotes: true })
          }
        })
        .catch((error) => {
          setStatus("error")
          if (error?.response?.data?.errorCode == "USER_NOT_FOUND") setNewUserModal(true)
        })
    }
  }, [status, getProfile, updateProfile])

  // clear status after receiving valid profileData
  useEffect(() => {
    if (getProfileData || updateProfileData) setStatus("")
  }, [getProfileData, updateProfileData])

  // memo to ensure profileData is up to date if updateProfile is called
  const profileData = useMemo(() => {
    if (updateProfileData) return updateProfileData
    else return getProfileData
  }, [getProfileData, updateProfileData])

  // dark mode variables
  const DARK_MODE_KEY = "thunder.darkMode"
  const DARK_MODE_CLASSNAME = "awsui-polaris-dark-mode"
  const [darkMode, setDarkMode] = useState(window.localStorage.getItem(DARK_MODE_KEY) == "true")

  useEffect(() => {
    window.localStorage.setItem(DARK_MODE_KEY, darkMode.toString())

    if (darkMode) {
      document.body.classList.add(DARK_MODE_CLASSNAME)
    } else {
      document.body.classList.remove(DARK_MODE_CLASSNAME)
    }
  }, [darkMode])

  return (
    <ProfileContext.Provider
      value={{
        getProfile,
        updateProfile,
        profileLoading: getProfileLoading || updateProfileLoading,
        profileError: getProfileError || updateProfileError,
        profileData,
        darkMode,
        setDarkMode,
        setNotesModal,
        setNewUserModal,
      }}
    >
      <NewUserModal visible={newUserModal} />
      <ReleaseNoteModal visible={notesModal} />
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfile = () => useContext(ProfileContext)

export default ProfileContextProvider
