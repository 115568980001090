import { Header, HelpPanel, Link } from "@amzn/awsui-components-react"

const toolsTopics = {
  main: "this is a tools topic",
  designWin: (
    <HelpPanel header={<Header>Design Win</Header>}>
      A Design Win is defined as any solution or product that leverages AWS Services and infrastructure and can be repeatably sold to a customer to meet a key
      business challenge or outcome. Typically, these can take the form of Software (IP), SaaS, AMI, Managed service offerings with IP, or Consulting offers
      with IP, and can be deployed by the customer (self-serve) or deployed with the help of a G/SI or ISV through customizations. Any partner type (such as
      ISV, BCAP, GSI, ISA, or SI) can be part of a combined solution (bundle offer/Tri-Party) or independently provide a solution. Pure consulting offers by
      partners that don&apos;t provide IP and don&apos;t have repeatability are not considered Design Wins.
      <br /> <br />
      You can read more on the wiki{" "}
      <Link external href="https://w.amazon.com/bin/view/AWS/Teams/APO/APOTech/TeamGoals/">
        here
      </Link>
    </HelpPanel>
  ),
  marqueeDesignWin: (
    <HelpPanel header={<Header>Marquee Design Win</Header>}>
      A Marquee Design Win follows all the guidelines of a Design Win, but has an additional requirement of an external press release or customer reference.
      Once your Design Win achieves these, you can upgrade it to a Marquee Design Win.
      <br /> <br />
      You can read more on the wiki{" "}
      <Link external href="https://w.amazon.com/bin/view/AWS/Teams/APO/APOTech/TeamGoals/">
        here
      </Link>
    </HelpPanel>
  ),
  externalTechnicalContent: (
    <HelpPanel header={<Header>External Technical Content</Header>}>
      External Technical Content is any content that the SA has authored, created, or supported with a public URL. The SA&apos;s name should be listed as an
      author or contributor. If this is not possible, such as with some joint partner publications, you will need to describe your contribution in Salesforce
      while documenting the activity. Most External Technical Content will require a Content Security Review (CSR). When submitting your tasks in Salesforce,
      ensure that you include the CSR SIM Ticket number in the subject line.
      <br /> <br />
      You can read more on the wiki{" "}
      <Link external href="https://w.amazon.com/bin/view/AWS/Teams/APO/APOTech/TeamGoals/Definitions/#HExternalTechnicalContent">
        here
      </Link>
    </HelpPanel>
  ),
  internalTechnicalContent: (
    <HelpPanel header={<Header>Internal Technical Content</Header>}>
      Internal Tech content can include things like PRFAQs, or enablement content created to educate internal AWS teams in Wisdom, High-spot, Knowledgemine, or
      Mindtickle. Webinar presentations and demos for a single event would fall under this category. .
      <br /> <br />
      You can read more on the wiki{" "}
      <Link external href="https://w.amazon.com/bin/view/AWS/Teams/APO/APOTech/TeamGoals/Definitions/#HInternalTechnicalContent">
        here
      </Link>
    </HelpPanel>
  ),
  publicSpeaking: (
    <HelpPanel header={<Header>Public Speaking</Header>}>
      Public speaking involves presenting in an open-door setting to a general audience, not just to a specific customer or partner. This requires AWS Speaker
      Certification and a public link listing you as a speaker. Qualifying presentations must be to an audience of multiple customers/partners, either virtually
      or in-person. Exceptions are made for speaking or panel participation at large external events, such as re:Invent and the Global Partner Summit.
      <ul>
        <li>At least 30 minutes in length</li>
        <li>Minimum 25 attendees from various partners</li>
        <li>A public link listing you as a speaker</li>
      </ul>
      While not a hard criteria, sessions should aim to attain a CSAT score above 4 out of 5. Sessions with a recorded score lower than this should be discussed
      with your manager before submitting.
      <br /> <br />
      You can read more on the wiki{" "}
      <Link external href="https://w.amazon.com/bin/view/AWS/Teams/APO/APOTech/TeamGoals/Definitions/#HPublicSpeaking">
        here
      </Link>
    </HelpPanel>
  ),
  serviceBeta: (
    <HelpPanel header={<Header>Service Beta</Header>}>
      A feature or service is considered an eligible Service Beta if it meets either of the following criteria:
      <ul>
        <li>
          Has gone to General Availability (GA&apos;ed) since the last re:Invent (2023), is currently in Public Preview, or is currently in Private Preview.
        </li>
        <li>Is included in the Lighthouse Service Beta Program.</li>
      </ul>
      To complete a Service Beta Activity the PSA must complete a demonstrable outcome with the AWS Partners partner team on an Eligible Service Beta feature or
      service. The following content will describe the expectations to complete a Service Beta Activity in additional detail, describe how to record the Service
      Beta Activity, and answer frequent questions and concerns. The number of Eligible Service Betas will increase during the year as new services and features
      are GA&apos;ed or new private preview and public previews are made available. Establishing relationships with the AWS product teams, Industry and Partner
      Solutions (IPS) COE relevant to your partner, or participation with a Technical Field Community are methods to identity new features and services in
      development and will be released into private preview or public preview. The resources below will assist PSAs to identify a subset of Eligible Service
      Beta features and services, but should not be considered comprehensive:
      <ul>
        <li>Re:invent 2023 cheat sheet: a document containing all of the services and features announced at re:Invent 2023.</li>{" "}
        <li>
          PartnerEquip: Virtual: is a benefit that unlocks early access for partners&apos; strategic content, such as highly-confidential,
          non-disclosure-agreement (NDA)-bound product roadmaps, feature release previews only for partners in AWS Service Delivery, Service Ready, Competency,
          and MSP.
        </li>{" "}
        <li>What&apos;s New at AWS: a list with all announced features and services.</li>
      </ul>
      The Service Launch Engagement Center(SLEC) is a new tool available in Private Preview to assist PSAs to identify eligible service betas and reduce the
      level of effort to onboard and manage a partner through the service beta process, while standardizing the engagement process between services teams,
      partners, and PSAs.
      <br /> <br />
      You can read more on the wiki{" "}
      <Link external href="https://w.amazon.com/bin/view/AWS/Teams/APO/APOTech/TeamGoals/Definitions/#HServiceBeta">
        here
      </Link>
    </HelpPanel>
  ),
  strategicEnablement: (
    <HelpPanel header={<Header>Strategic Enablement</Header>}>
      Strategic Enablement is defined as activities that support the practice development, understanding of, or deep skills in AWS. Regardless of where on their
      cloud journey the partner is, our team should be constantly supporting their ability to grow their AWS practice, train their people, and make sure they
      understand the latest services, patterns, and techniques which will help them win more business with our customers. Enablement is not meant to track
      common activities with our partners, such as phone calls, small meetings or responding to emails, etc. This input is intended for large scale activities
      with meaningful impact on the partner and their teams. Please work with your manager for to discuss if a specific activity falls into this category.
      Example Strategic Enablement activities include:
      <ul>
        <li>Dev/AWSome Days</li>
        <li>Workshops</li>
        <li>Immersion Days</li>
        <li>Game Days</li>
        <li>Bootcamps</li>
        <li>Jam Sessions</li>
        <li>Hack-a-Thons</li>
      </ul>
      You can read more on the wiki{" "}
      <Link external href="https://w.amazon.com/bin/view/AWS/Teams/APO/APOTech/TeamGoals/Definitions/#HStrategicEnablement">
        here
      </Link>
    </HelpPanel>
  ),
  technicalValidation: (
    <HelpPanel header={<Header>Technical Validations</Header>}>
      Technical Validations require working with a Partner to deep dive into a design and generate a written document on the full design/implementation.
      Customer Architecture Reviews, defined as a review done specific to a customer implementation or requirement (tracked through Opportunities in SFDC which
      eventually gets translated into a Launched Opportunity in SFDC) Solutions Architecture Reviews, validations conducted for partner solutions that may have
      not been deployed to end customers yet Well Architected Review (WA Reviews) Technical Baseline Reviews such as Foundational Technical Reviews (FTRs),
      Cloud Adoption Framework (CAF), etc. Executing these validations Technical validations may also include reviewing the Bill of Materials (BOM), cost
      optimization, or security enhancement recommendations, as well as supporting Partners responding to an RFI/RFP, review of Statement of Work (SoW) for a
      funding request, and resolving any technical problems related to solution or architectural design in customer engagements.
      <br /> <br />
      You can read more on the wiki{" "}
      <Link external href="https://w.amazon.com/bin/view/AWS/Teams/APO/APOTech/TeamGoals/Definitions/#HTechnicalValidations">
        here
      </Link>
    </HelpPanel>
  ),
  partnerProgramTechnicalValidation: (
    <HelpPanel header={<Header>Partner Program Technical Validations</Header>}>
      Partner Program Technical Validations include all design reviews conducted for Partner Programs such as Competency, Service Delivery Program & Service
      Ready, etc. Technical Baseline Reviews such as Foundational Technical Reviews (FTRs) will also fall here if conducted for a specific APO Program.
      <br /> <br />
      You can read more on the wiki{" "}
      <Link external href="https://w.amazon.com/bin/view/AWS/Teams/APO/APOTech/TeamGoals/Definitions/#HPartnerProgramTechnicalValidations">
        here
      </Link>
    </HelpPanel>
  ),
}

export default toolsTopics
