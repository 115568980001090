import { useCollection } from "@amzn/awsui-collection-hooks"
import { Container, Header, SpaceBetween, Table } from "@amzn/awsui-components-react"
import productsApi, { ProductResponse } from "api/products"
import { Opportunity } from "models/opportunity"
import usePromise from "pmsa-polaris/hooks/usePromise"
import React, { useEffect } from "react"

type Props = {
  opportunity?: Opportunity
  loading: boolean
  error: any
}

const COLUMN_DEFINITIONS = [
  {
    id: "name",
    header: "Name",
    cell: (product: ProductResponse) => product.name,
    sortingField: "name",
  },
  {
    id: "offeringType",
    header: "Offering Type",
    cell: (product: ProductResponse) => product.offeringType,
    sortingField: "offeringType",
  },
  {
    id: "sId",
    header: "Solution ID",
    cell: (product: ProductResponse) => product.apnOfferingId,
    sortingField: "sId",
  },
  {
    id: "ftrStatus",
    header: "FTR Status",
    cell: (product: ProductResponse) => product.ftrStatus,
    sortingField: "ftrStatus",
  },
]

const LineItemTable: React.FC<Props> = ({ loading, opportunity, error }) => {
  const hasLineItem = opportunity?.opportunityLineItems && opportunity?.opportunityLineItems.length > 0

  // account products api
  const [{ loading: productsLoading, data: productsFull, error: productsError }, getProducts] = usePromise(productsApi.getAll)

  useEffect(() => {
    if (productsError || productsLoading) {
      return
    }

    if (hasLineItem && opportunity?.opportunityLineItems && !productsFull) {
      getProducts(opportunity?.opportunityLineItems.toString())
    }
  }, [productsLoading, productsError, opportunity, hasLineItem, productsFull, getProducts])

  const products = productsFull || []

  const { items, collectionProps, paginationProps } = useCollection(products, {
    pagination: {
      pageSize: 10,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "name",
        },
        isDescending: false,
      },
    },
    selection: {},
  })

  return opportunity && opportunity?.workstreamName && opportunity?.workstreamName === "[Design Win] Partner Offering" ? (
    <Container>
      <SpaceBetween size="s">
        <Header>Attached Solutions</Header>
        <Table {...collectionProps} loading={productsLoading} loadingText="Loading solutions" columnDefinitions={COLUMN_DEFINITIONS} items={items} />
      </SpaceBetween>
    </Container>
  ) : null
}

export default LineItemTable
