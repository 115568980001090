import { BaseOpportunityTask } from "./shared"
import { Task } from "./task"

export const OPPORTUNITY_STAGE = [
  "Prospect",
  "In Progress",
  "Qualified",
  "On-hold",
  "Technical Validation",
  "Business Validation",
  "Committed",
  "Completed",
  "Closed Lost",
] as const

export const GOALS = ["Design Win", "Marquee Design Win"]

export type Opportunity = BaseOpportunityTask & {
  stageName: typeof OPPORTUNITY_STAGE[number]
  accountName: string
  closeDate: string
  nextStep: string
  lastModifiedDate: string
  createdDate: string
  description: string
  hygiene: string[]
  hygieneCount?: number
  propensity: string[]
  propensityCount?: number
  propensityDays?: number
  apnFocusedProgram: string
  closedLostCategory: string
  closedLostReasonNotes: string
  name: string
  fiscalYear?: number
  accountId: string
  tasks: Readonly<Task[]>
  owner: string
  externalLink?: string
  vertical?: string
  verticalDetails?: (string | undefined)[]
  campaignId?: string
  goals?: string
  designWinDetail?: string
  recordTypeName?: string
  opportunityLineItems?: string[]
}

export const VERTICALS = {
  "Advertising & Marketing": [],
  Agriculture: ["Academic Research", "Equipment Manufacturers", "Food Processors", "Food Producers", "Seed, Chem, Fert"],
  Automotive: ["Automotive - Other", "AutoTech", "Dealer", "OEM", "Supplier"],
  "Consumer Packaged Goods": [
    "CX Sales & Marketing",
    "Data & Analytics",
    "Enterprise Applications",
    "Manufaturing",
    "Migration Services, Lift & Shift (CPG)",
    "R&D",
    "Supply Chain",
  ],
  Defense: ["4th Estate", "Air Force", "Army", "Navy"],
  Education: ["Academic Medicine", "Higher Education", "K-12"],
  Energy: [
    "Brokerage and Trading",
    "Completions, Fracking",
    "Downstream (Refinery, Gas station, Retail)",
    "Drilling and Formation Evaluation",
    "Midstream (pipes, maintenance, inspection, transport)",
    "Oil & Gas",
    "Predictive Maintenance",
    "Quality, Health, Safety and Environment (QHSE)",
    "Reservoir Modeling",
    "Seismic Collection and Archive, Data Management",
    "Seismic Interpretation",
    "Seismic Processing and Reservoir Simulation",
    "Well and Field Production",
    "Well Planning and Placement",
  ],
  "Engineering, Construction & Real Estate": ["Construction", "Consulting", "Engineering", "ERP", "Real Estate"],
  "Financial Services": ["Banking", "Capital Markets", "Insurance", "Payments"],
  "FSI-Banking": [
    "AI/ML",
    "Cloud Adoption Office",
    "Core Systems",
    "Data Lake",
    "Digital User Engagement",
    "Mass Migration",
    "Risk/Grid",
    "Transformation Consulting Strategy",
  ],
  "FSI-Capital Markets": [
    "AI/ML",
    "Cloud Adoption Office",
    "Core Systems",
    "Data Lake",
    "Market Data and Content",
    "Mass Migration",
    "Risk/Grid",
    "Transformation Consulting Strategy",
  ],
  "FSI-Insurance": [
    "AI/ML",
    "Cloud Adoption Office",
    "Core Systems",
    "Data Lake",
    "Digital User Engagement",
    "Mass Migration",
    "Risk/Actuarial",
    "Transformation Consulting Strategy",
  ],
  "FSI-Payments Processing": ["AI/ML", "Cloud Adoption Office", "Core Systems", "Data Lake", "Mass Migration", "PCI", "Transformation Consulting Strategy"],
  Gaming: ["Distribution / Platform Services", "Game Services & Technology", "User Engagement and Marketing"],
  Government: ["Civilian Government", "Environmental Protection Agency", "Federal Not For Profit", "Justice", "National Security", "State and Local"],
  "Healthcare & Life Sciences": ["Biopharma", "Genomics", "Med Device", "Payer", "Provider"],
  Hospitality: ["Casino & Entertainment Venues", "Cruise Lines", "Hospitality Services", "Lodging & Accommodation", "Restaurants, Catering and Food Services"],
  "Industrial Software": [
    "Civil Engineering",
    "Computer Aided Engineering",
    "Computer Aided Manufacturing",
    "Electronic Design Automation",
    "Industrial Asset Management",
    "Industrial IoT",
    "Product Design & Simulation",
    "Production - Automation",
    "Production Logistics",
    "Production - MES",
    "Production Planning",
    "Production - SCADA",
    "Product Lifecycle Management",
  ],
  Manufacturing: [],
  "Media & Entertainment": [
    "Advertising Management",
    "AI/ML",
    "Analytics",
    "Broadcast",
    "Broadcast Playout & Distribution",
    "Content Acquisition",
    "Content Production/Creation",
    "Digital Publishing",
    "Distribution - Digital Publishing",
    "Distribution - DTC",
    "MAM/DAM/Archive",
    "Media Supply Chain",
    "OTT",
    "Post/Production",
  ],
  "Mining & Natural Resources": [],
  "Non-Profit": [
    "Arts, Culture, Humanities",
    "Education",
    "Environment and Animals",
    "Health",
    "Human Services",
    "International Affairs",
    "Public-Society Benefit",
    "Religion",
  ],
  "Power & Utilities": [
    "Asset & Workforce Management",
    "Customer Engagement",
    "Energy Supply Transition",
    "IT Transformation",
    "Meter Data Analytics",
    "OT Transformation",
  ],
  "Process Manufacturing": [],
  "Retail, Wholesale and Distribution": [
    "Advanced Retail Data Science",
    "Core Retail Business Applications",
    "Corporate Merchandising & Planning",
    "Customer Engagement",
    "Migration Services, Lift & Shift (Retail)",
    "Physical, Digital & Virtual Store",
    "Supply Chain & Distribution",
  ],
  Semiconductor: ["Fabless Semiconductor", "Integrated Semiconductor", "Semiconductor Foundry and Packaging", "Semiconductors", "Semiconductors - Others"],
  "Supply Chain": [],
  Telco: [
    "Business Support Solutions (BSS)",
    "Consulting and MSP Led Services",
    "Customer Interaction Layer (Web, PoS, CRM)",
    "Edge Computing",
    "Internet of Things for CSP's",
    "IP Network Solutions",
    "Operations Support Solutions (OSS)",
    "Wireless Network Solutions",
  ],
  "Transportation & logistics": [],
  Travel: ["Airlines", "Airports", "Ground Transportation", "Travel Services"],
} as const

export const VERTICALS_LIST = Object.keys(VERTICALS) as Array<keyof typeof VERTICALS>
