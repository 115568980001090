import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout"
import { useWorkstreamCatalog } from "context/WorkstreamCatalogContext"
import { getIn, useFormikContext } from "formik"
import { SelectFormField } from "pmsa-polaris/components/FormFields"
import useNamePrefix from "pmsa-polaris/hooks/useNamePrefix"
import { useEffect, useMemo } from "react"

type Props = {
  namePrefix?: string
  save?: boolean
  disabled?: boolean
}

const GEO_REGION_KEY = "thunder.geo_region"

const GeoRegionSelector: React.FC<Props> = ({ namePrefix, save, disabled }) => {
  const getName = useNamePrefix(namePrefix)

  const { values, initialValues, setFieldValue } = useFormikContext()
  const { geoOptions, regionOptionsByGeo = {}, geoRegions } = useWorkstreamCatalog()

  const geoName = getName("geo")
  const regionName = getName("region")

  const { selectedGeo, selectedRegion, initialGeo, initialRegion } = useMemo(() => {
    const selectedGeo = (getIn(values, geoName) as string) || ""
    const selectedRegion = (getIn(values, regionName) as string) || ""
    const initialGeo = (getIn(initialValues, geoName) as string) || ""
    const initialRegion = (getIn(initialValues, regionName) as string) || ""

    return {
      selectedGeo,
      selectedRegion,
      initialGeo,
      initialRegion,
    }
  }, [values, geoName, regionName, initialValues])

  useEffect(() => {
    if (geoRegions && selectedGeo) {
      const firstRegion = geoRegions[selectedGeo] ? geoRegions[selectedGeo][0] : undefined
      const regionSet = new Set(geoRegions[selectedGeo])

      const savedOrInitialRegionInSelected = regionSet.has(initialRegion) ? initialRegion : undefined

      setFieldValue(regionName, savedOrInitialRegionInSelected || firstRegion)
    }
  }, [geoRegions, initialGeo, initialRegion, regionName, selectedGeo, setFieldValue])

  useEffect(() => {
    if (geoRegions && !selectedGeo && !selectedRegion) {
      const firstGeo = Object.keys(geoRegions)[0]
      setFieldValue(geoName, firstGeo)
    }
  }, [geoName, geoOptions, geoRegions, selectedGeo, selectedRegion, setFieldValue])

  return (
    <ColumnLayout columns={3}>
      <SelectFormField label="Geo" disabled={disabled} selectedAriaLabel="Selected" options={geoOptions} ariaRequired name={geoName} required />
      <SelectFormField
        label="Region"
        disabled={disabled}
        selectedAriaLabel="Selected"
        options={regionOptionsByGeo[selectedGeo]}
        ariaRequired
        name={regionName}
        required
      />
    </ColumnLayout>
  )
}

export default GeoRegionSelector
