import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel"

const ToolsPanel = () => (
  <HelpPanel header={<h2>Opportunities List</h2>}>
    <p>
      This page displays a list of all of your opportunities from SFDC. It is intended to show you which opportunities are coming close to their due dates and
      which have already been flagged with hygiene issues. You can quickly update your opportunities with new target dates via the Action button on this page.
      You can also click on the links under the Opportunity column to take you directly to the opportunity within SFDC.
    </p>
  </HelpPanel>
)

export default ToolsPanel
