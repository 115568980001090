import { Box, ColumnLayout, Container, Header, SpaceBetween, StatusIndicator } from "@amzn/awsui-components-react"
import { Opportunity } from "models/opportunity"
import React from "react"

type Props = {
  opportunity?: Opportunity
  loading: boolean
  error: any
}

const HygieneDetails: React.FC<Props> = ({ opportunity, loading, error }) => {
  const hasHygiene = opportunity?.hygiene && opportunity.hygiene.length > 0
  const hasPropensity = opportunity?.propensity && opportunity.propensity.length > 0

  return (
    (opportunity && !loading && (
      <ColumnLayout columns={2}>
        <Container
          header={
            <SpaceBetween direction="horizontal" size="xxs">
              <Box padding={{ vertical: "xs", horizontal: "n" }}>{hasHygiene && <StatusIndicator type="error" />}</Box>
              <Header variant="h3">Hygiene</Header>
            </SpaceBetween>
          }
        >
          {opportunity?.hygiene?.map((hygiene, index) => (
            <React.Fragment key={`hygiene-${index}`}>
              <span>{hygiene}</span>
              <br />
            </React.Fragment>
          )) || "-"}
        </Container>
        <Container
          header={
            <SpaceBetween direction="horizontal" size="xxs">
              <Box padding={{ vertical: "xs", horizontal: "n" }}>{hasPropensity && <StatusIndicator type="warning" />}</Box>
              <Header variant="h3">Propensity</Header>{" "}
            </SpaceBetween>
          }
        >
          {opportunity?.propensity?.map((propensity, index) => (
            <React.Fragment key={`propensity-${index}`}>
              <span>{propensity}</span>
              <br />
            </React.Fragment>
          )) || "-"}
        </Container>
      </ColumnLayout>
    )) ||
    null
  )
}

export default HygieneDetails
