import { Alert, Spinner } from "@amzn/awsui-components-react"
import { DetailsDisplay } from "components/DetailsDisplay"
import { Task } from "models/task"
import moment from "moment"
import NavigationLink from "pmsa-polaris/components/NavigationLink"
import config from "pmsa-polaris/config"
import { capitalize } from "pmsa-polaris/utils"
import React from "react"
import { routeParams } from "routes"

export type Props = {
  task?: Task
  loading: boolean
  error: any
}

const { sfdcBaseUrl } = config

export const DetailsSection: React.FC<Props> = ({ task, loading, error }) => {
  const isLoading = loading || false

  moment().format("MM/DD/YYYY")

  return (
    (isLoading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (task && (
      <DetailsDisplay
        copyToClipboard={true}
        details={[
          ["ID", task.id],
          ["Name", task.subject],
          ["Account Name (Partner)", task.accountName],
          ["Stage", task.status],
          ["Type", task.type],
          ["Time Spent (Hrs)", task.timeSpentHrs],
          [
            task.relatedType && capitalize(task.relatedType),
            // eslint-disable-next-line react/jsx-key
            task.relatedId && (
              <NavigationLink
                href={
                  task.relatedType === "opportunity"
                    ? routeParams.opportunitiesDetails({
                        id: task.relatedId,
                      })
                    : `${sfdcBaseUrl}/${task.relatedId ?? ""}`
                }
              >
                {task.relatedName}
              </NavigationLink>
            ),
          ],

          ["BD Activity Type", task.bdActivityType || "-"],
          ["SA Activity", task.saActivity || "-"],
          ["Comments", task.description],
          ["Due Date", task.activityDate],
          [
            "Last Modified Date",
            // eslint-disable-next-line react/jsx-key
            moment(task?.lastModifiedDate).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
          ],
        ]}
      />
    )) ||
    (error && (
      <Alert visible={true} type="error" header="Failed to fetch details">
        Could not get details due to an error
      </Alert>
    )) ||
    null
  )
}
