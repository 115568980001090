import { Alert, SpaceBetween, Spinner } from "@amzn/awsui-components-react"
import { DetailsDisplay } from "components/DetailsDisplay"
import { Opportunity } from "models/opportunity"
import moment from "moment"
import React, { useState } from "react"

type Props = {
  opportunity?: Opportunity
  loading: boolean
  error: any
}

const DetailsSection: React.FC<Props> = ({ opportunity, loading, error }) => {
  const isLoading = loading || false
  const [mpAlert, setMpAlert] = useState(true)

  moment().format("MM/DD/YYYY")

  let typeWording = opportunity?.workstreamName || "invalid"
  if (opportunity?.workstreamName === "[Design Win] Partner Offering") {
    typeWording = opportunity?.goals === "Marquee Design Win" ? `${typeWording} (Marquee Design Win)` : typeWording
  }

  const mpError =
    opportunity?.designWinDetail === "Marketplace Listing" &&
    ((opportunity?.externalLink && !opportunity?.externalLink.startsWith("https://aws.amazon.com/marketplace")) || !opportunity?.externalLink)
      ? opportunity.stageName === "Completed"
        ? "error"
        : "warning"
      : "info"

  return (
    (isLoading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (opportunity && (
      <SpaceBetween size="m">
        <DetailsDisplay
          copyToClipboard={true}
          details={[
            ["ID", opportunity?.id],
            ["Name", opportunity?.name],
            ["Account Name (Partner)", opportunity?.accountName],
            ["Type", typeWording],
            ["Geo/Region", opportunity?.geo + "/" + opportunity?.region],
            ["Description", opportunity?.description],
            ["Next Step", opportunity?.nextStep],
            ["Stage Name", opportunity?.stageName],
            // eslint-disable-next-line react/jsx-key
            ["Close Date", moment(opportunity?.closeDate).format("MM/DD/YYYY")],
            ["Owner", opportunity?.owner],
            [
              "Last Modified Date",
              // eslint-disable-next-line react/jsx-key
              moment(opportunity?.lastModifiedDate).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
            ],
            ["External Link", opportunity?.externalLink],
          ]}
        />
        {mpAlert && mpError != "info" && (
          <Alert dismissible onDismiss={() => setMpAlert(false)} type={mpError} header="Valid External Link Needed for Marketplace Design Win">
            <p>
              You chose a Marketplace Solution on the create/edit page but your external link is either missing or invalid.
              {mpError === "warning" && " This is just a warning until you complete the opportunity."}
            </p>
          </Alert>
        )}
      </SpaceBetween>
    )) ||
    (error && (
      <Alert type="error" header="Failed to fetch details">
        Could not get details due to an error
      </Alert>
    )) ||
    null
  )
}

export default DetailsSection
