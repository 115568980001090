import customerOpportunityApi from "api/customerOpportunities"
import tasksApi, { TaskRequest } from "api/tasks"
import { CustomerOpportunity } from "models/customerOpportunity"
import { Task } from "models/task"
import usePromise from "pmsa-polaris/hooks/usePromise"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import React, { useCallback, useContext, useEffect, useState } from "react"

import { useProfile } from "./ProfileContext"

const DEFAULT_CONTEXT: {
  opportunityList: {
    loading: boolean
    error: any
    data: Readonly<CustomerOpportunity>[] | undefined
  }

  opportunity: {
    loading: boolean
    error: any
    data: Readonly<CustomerOpportunity> | undefined
  }

  getCustomerOpportunities: () => Promise<Readonly<CustomerOpportunity>[]>
  getCustomerOpportunity: (id: string) => Promise<Readonly<CustomerOpportunity>>
  setCurrentCustomerOpportunity: (id: string) => boolean
} = {
  opportunityList: {
    loading: false,
    error: null,
    data: undefined,
  },

  opportunity: {
    loading: false,
    error: null,
    data: undefined,
  },

  getCustomerOpportunities: () => Promise.resolve([]),
  getCustomerOpportunity: (id: string) => Promise.resolve({} as Readonly<CustomerOpportunity>),
  setCurrentCustomerOpportunity: (id: string) => false,
}

const CustomerOpportunityTaskContext = React.createContext(DEFAULT_CONTEXT)

const CustomerOpportunityContextProvider: React.FC = ({ children }) => {
  const queryParams = useQueryString()
  const { alias } = queryParams
  const { profileData } = useProfile()
  const partnerAccountIds = (profileData?.selectedPartners || []).map((item) => item?.value) as string[]

  const [opp, setOpp] = useState<CustomerOpportunity | undefined>(undefined)
  const [oppList, setOppList] = useState<CustomerOpportunity[] | undefined>(undefined)

  // opportunity API calls
  const [getOppListResponse, getOpportunityList] = usePromise(customerOpportunityApi.getAll)
  const [getOppResponse, getOpportunity] = usePromise(customerOpportunityApi.get)

  // ensure that opportunity list is loaded
  useEffect(() => {
    if (getOppListResponse.data) setOppList(getOppListResponse.data)
    else if (!getOppListResponse.loading && profileData && !getOppListResponse.error) getOpportunityList(alias, partnerAccountIds)
  }, [profileData, getOppListResponse, alias, getOpportunityList, partnerAccountIds])

  // after getOpportunity, set as current opportunity and update item in opportunity list
  useEffect(() => {
    if (getOppResponse.data) {
      setOpp(getOppResponse.data)
      const id = getOppResponse.data.id
      setOppList((prev) => {
        const index = prev?.findIndex((opp) => opp.id === id)
        if (prev && index && index >= 0) prev[index] = getOppResponse.data as CustomerOpportunity
        return prev
      })
    }
  }, [getOppResponse.data])

  // functions
  const setCurrentCustomerOpportunity = useCallback(
    (id: string) => {
      if (id === "") setOpp(undefined)

      const opp = oppList?.find((opp) => opp.id === id)
      if (opp) {
        setOpp(opp)
        return true
      } else return false
    },
    [oppList]
  )

  // return context provider
  return (
    <CustomerOpportunityTaskContext.Provider
      value={{
        opportunityList: {
          loading: getOppListResponse.loading,
          error: getOppListResponse.error,
          data: oppList,
        },

        opportunity: {
          loading: getOppResponse.loading,
          error: getOppResponse.error,
          data: opp,
        },

        getCustomerOpportunities: useCallback(() => getOpportunityList(alias, partnerAccountIds), [partnerAccountIds, alias, getOpportunityList]),
        getCustomerOpportunity: getOpportunity,
        setCurrentCustomerOpportunity: setCurrentCustomerOpportunity,
      }}
    >
      {children}
    </CustomerOpportunityTaskContext.Provider>
  )
}

export const useCustomerOpportunityTaskContext = () => useContext(CustomerOpportunityTaskContext)

export default CustomerOpportunityContextProvider
