import request from "pmsa-polaris/api/request"

export type ProfileData = {
  region?: string
  geo?: string
  sfdcUserDomain?: string
  seeReleaseNotes?: boolean
  selectedPartners?: { label: string; value: string; description: string }[]
}

export type ProfileResponse = Readonly<ProfileData>

const profilesApi = {
  get: async () => request.get<ProfileResponse>("/profile"),

  update: (params: ProfileData) => request.put<ProfileResponse>("/profile", params),
}

export default profilesApi
