import { Campaign } from "models/campaign"
import request from "pmsa-polaris/api/request"

export type CampaignResponse = Readonly<Campaign>

const campaignsApi = {
  search: (name: string) => request.get<CampaignResponse[]>(`/campaigns/search/${name}`),
}

export default campaignsApi
