import MainLayout from "pmsa-polaris/components/MainLayout"

import { GoalsTable } from "./GoalsTable"
import toolsTopics from "./toolsTopics"

const GoalsDashboard: React.FC = () => {
  return (
    <MainLayout contentType="table" toolsTopics={toolsTopics}>
      <GoalsTable />
    </MainLayout>
  )
}

export default GoalsDashboard
