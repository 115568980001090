import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel"

const ToolsPanel = () => (
  <HelpPanel header={<h2>PAI Opportunities List</h2>}>
    <p>
      This page displays a list of all of your partner account involved (PAI) opportunities from SFDC. You can also click on the links under the Opportunity
      column to take you directly to the opportunity within SFDC.
    </p>
  </HelpPanel>
)

export default ToolsPanel
