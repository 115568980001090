import { BreadcrumbGroup, Button, Container, ContentLayout, Header, SpaceBetween } from "@amzn/awsui-components-react"
import { useCustomerOpportunityTaskContext } from "context/CustomerOpportunityContext"
import MainLayout from "pmsa-polaris/components/MainLayout"
import config from "pmsa-polaris/config"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import ActivitiesContainer from "./ActivitiesContainer"
import CustomerDetailsSection from "./CustomerOpportunityDetailsSection"

const { sfdcBaseUrl } = config

const OpportunityDetails = () => {
  const { id: idParam } = useParams()
  const id = idParam!

  const navigate = useNavigate()

  const { opportunity, getCustomerOpportunity, setCurrentCustomerOpportunity } = useCustomerOpportunityTaskContext()

  useEffect(() => {
    if (!setCurrentCustomerOpportunity(id)) getCustomerOpportunity(id)
  }, [id, setCurrentCustomerOpportunity, getCustomerOpportunity])

  return (
    <MainLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "CustomerOpportunities", href: "/customerOpportunities" },
            { text: id, href: "" },
          ]}
          onFollow={(e) => {
            e.preventDefault()
            navigate(e.detail.href)
          }}
        />
      }
    >
      <ContentLayout header={<Header>CustomerOpportunity Details</Header>}>
        <SpaceBetween size="l">
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="m">
                    <Button iconAlign="right" iconName="external" href={`${sfdcBaseUrl}/${id ?? ""}`} target="_blank">
                      View in SFDC
                    </Button>
                    <Button
                      iconName="refresh"
                      variant="icon"
                      onClick={(e) => {
                        e.preventDefault()
                        getCustomerOpportunity(id)
                      }}
                    />
                  </SpaceBetween>
                }
              >
                {opportunity.data?.title || "Customer Opportunity"}
              </Header>
            }
          >
            <CustomerDetailsSection opportunity={opportunity.data} error={opportunity.error} loading={opportunity.loading} />
          </Container>
          <ActivitiesContainer opportunity={opportunity.data} loading={opportunity.loading} error={opportunity.error} />
        </SpaceBetween>
      </ContentLayout>
    </MainLayout>
  )
}

export default OpportunityDetails
