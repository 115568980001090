import { CustomerOpportunity } from "models/customerOpportunity"

export type CustomerOpportunityRequest = Omit<
  CustomerOpportunity,
  // eslint-disable-next-line
  | "lastModifiedDate"
  | "createdDate"
  | "id"
  | "name"
  | "accountName"
  | "partnerAccountName"
  | "apnFocusedProgram"
  | "closedLostCategory"
  | "closedLostReasonNotes"
  | "tasks"
>

import request from "pmsa-polaris/api/request"

import { TaskResponse } from "./tasks"

export type CustomerOpportunityResponse = Readonly<CustomerOpportunity>

const customerOpportunitiesApi = {
  getAll: (alias?: string, partnerAccountIds?: string[]) => {
    return request.get<CustomerOpportunity[]>("/opportunities", {
      params: { expand: false, alias: alias, partnerAccountIds: partnerAccountIds?.join(",") },
    })
  },

  get: (id: string) => request.get<CustomerOpportunityResponse>(`/opportunities/${id}`),

  update: (id: string, params: CustomerOpportunityRequest) => request.put<CustomerOpportunityResponse>(`/opportunities/${id}`, params),

  getTasks: (id: string) => request.get<TaskResponse[]>(`/opportunities/${id}/tasks`),

  search: (name: string) => request.get<CustomerOpportunityResponse[]>(`/opportunities/search/${name}`),
}

export default customerOpportunitiesApi
